import { db } from "../db/firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { weeksData } from "../data";
import { getWeeksCollection , getWeekDataByName } from "./prizesAPI";
import Prizes from "../pages/Prizes";


export const fetchWinnersByWeekName = async (weekName) => {
    try {
        const weeksCollectionRef = collection(db, "weeks");
        const q= query(weeksCollectionRef, where("weekName", "==", weekName));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.empty){
            console.log(`No week found with name: ${weekName}`);
            return null;
        }

        const weekDoc = querySnapshot.docs[0];
        const weekData = weekDoc.data();

        const winnersData = weekData || [];

        return winnersData;
    } catch (error) {
        console.error("Error fetching winners: ", error);
        throw error;
    }
    }

export const updateWinnerCards = async (weekName, winnerCards) => {
    try {
        
        const weekData = await getWeekDataByName(weekName);
        if(!weekData){
            console.error(`No week found with name: ${weekName}`);
            return false;
        }

       const weekDocRef = doc(db, "weeks", weekData.id);

       const weekDoc = await getDoc(weekDocRef);
       const currentWinners = weekDoc.data().winners;

       const updatedWinners = currentWinners.map((currentWinner, index) => {
        const newWinnerData = winnerCards[index] || {};
        return {
            studentId: newWinnerData.studentId ,
            winnerImg: newWinnerData.imgUrl ,
            prizeId : currentWinner.prizeId
        }
       });
       await updateDoc(weekDocRef , {
        winners : updatedWinners
       })
       return true;
    } catch (error) {
        console.error("Error updating winner cards" , error)
        return false;
    }
}

export const findWeekIdByName = async (weekName) => {
    const weeksCollectionRef = collection(db, "weeks");

    const q = query(weeksCollectionRef, where("weekName", "==", weekName));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        console.log(`No week found with name: ${weekName}`);
        return null;
    }

    const weekDoc = querySnapshot.docs[0];
    return weekDoc.id;
}
