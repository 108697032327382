import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";

const SelectField3 = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.value || null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = (e) => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (item) => () => {
    setSelectedOption(item.label);
    setIsOpen(false);
    props.onChange(props.id, item.value);
  };

  return (
    <div
      className="relative inline-flex w-full rounded-xl h-[30px]"
      style={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "#F8F8F8",
      }}
      ref={dropdownRef}
    >
      <div
        className="w-4/5 px-4 py-2 cursor-pointer flex items-center"
        onClick={handleToggle}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {selectedOption || "Select"}
      </div>

      <div
        className="absolute right-3 top-1/2 transform -translate-y-1/2 h-6 w-6 flex justify-center items-center cursor-pointer"
        onClick={handleToggle}
      >
        <IoIosArrowDown style={{ color: "#000000" }} />
      </div>

      {isOpen && (
        <div className="absolute top-full left-0 z-10 mt-1 w-full origin-top-right rounded-md bg-white max-h-60 overflow-y-auto custom-scrollbar">
          {props.items.map((item) => (
            <button
              type="button"
              onClick={onOptionClicked(item)}
              key={item.value}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-[#4FD1C5] hover:text-white focus:outline-none"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectField3;
