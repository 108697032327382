import React from "react";
import { theme } from "../../theme";

const CButton = ({ name, onClick , width}) => {
  return (
    <button
      className="inline-block rounded-lg p-[16px] text-center w-[172px]"
      style={{
        backgroundColor: theme.colors.primaryColor,
        ...theme.typography.primaryButtonText,
        width: width,
      }}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export default CButton;
