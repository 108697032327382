import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import Layout from "./components/layout/Layout";
import PrivateRoute from "./components/PrivateRoute";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route element={<PrivateRoute />}>
        <Route path="/admin/*" element={<Layout />} />
      </Route>
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default App;