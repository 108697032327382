import React, { useEffect } from "react";
import InputLogin from "../components/common/InputLogin";
import { theme } from "../theme";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../context/auth";
import { Spinner } from "@chakra-ui/react";

const Login = () => {
  const navigate = useNavigate();
  const { loginAdmin, loading, admin } = useAuth();
  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (admin) {
      navigate("/admin/dashboard");
    }
  }, [admin, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!loginData.email || !loginData.password) {
      toast.error("EmailId and password are required");
      return;
    }

    try {
      toast.loading("Please wait...");
      await loginAdmin(loginData.email, loginData.password);
      toast.dismiss();
      toast.success("Login successful");
      navigate("/admin/dashboard");
    } catch (error) {
      toast.dismiss();
      const errorCode = error.code;
      console.log("===>", errorCode);
      let errorMessage = "An error occurred. Please try again.";

      switch (errorCode) {
        case "auth/invalid-email":
          errorMessage = "Invalid email address format.";
          break;
        case "auth/invalid-credential":
          errorMessage = "Invalid credential";
          break;
        case "auth/user-not-found":
          errorMessage = "No user found with this email.";
          break;
        case "auth/wrong-password":
          errorMessage = "Incorrect password.";
          break;
        case "auth/network-request-failed":
          errorMessage = "Network error. Please check your connection.";
          break;
        default:
          errorMessage = error.message;
          break;
      }
      toast.error(errorMessage);
      console.log(errorCode, errorMessage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.id]: e.target.value });
  };

  return (
    <div className="flex flex-col md:flex-row w-screen h-screen">
      <div className="w-full md:w-[30%] h-[30%] md:h-[100%] bg-gray-800" />

      <form
        className="w-full md:w-[70%] h-[70%] md:h-[100%] bg-white flex flex-col items-center justify-center p-4 md:p-0"
        style={{
          fontFamily: "Mulish",
          color: "#333333",
        }}
        onSubmit={handleLogin}
      >
        <div className="flex flex-col justify-center w-full max-w-[350px]">
          <div
            className="mb-[3px] text-center md:text-left"
            style={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "17.57px",
            }}
          >
            Welcome back! 👋
          </div>

          <div
            className="mb-[30px] text-center md:text-left"
            style={{
              fontWeight: "700",
              fontSize: "22px",
              lineHeight: "27.61px",
            }}
          >
            Login to Reb Levik's Minyan
          </div>

          <div className="flex flex-col gap-[20px]">
            <InputLogin
              label="Email"
              placeholder="Please enter your email"
              type="text"
              onKeyDown={handleKeyDown}
              id="email"
              value={loginData.email}
              onChange={handleChange}
            />
            <InputLogin
              label="Password"
              placeholder="Enter password"
              type="password"
              onKeyDown={handleKeyDown}
              id="password"
              value={loginData.password}
              onChange={handleChange}
            />
            <button
              className="inline-block rounded-[4px] p-[16px] text-center w-full"
              style={{
                backgroundColor: theme.colors.primaryColor,
                ...theme.typography.primaryButtonText,
              }}
            >
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
