import React, { useState, useEffect } from "react";
import { theme } from "../theme";
import SearchBar from "../components/common/SearchBar";
import DownloadButton from "../components/common/DownloadButton";
import EnvelopeTable from "../components/EvelopeTable";
import Modal from "../components/common/Modal";
import AddMinyanContent from "../components/modalContent/AddMinyanContent";
import { useData } from "../context/data";
import { formatDate } from "../utils";

const Envelopes = () => {
  const { envelopes } = useData();
  const [isNameSelected, setIsNameSelected] = useState(false);
  const [minyanCount, setMinyanCount] = useState(0);
  const [selectedEnvelope, setSelectedEnvelope] = useState([]);
  const [showAddMinyanModal, setShowAddMinyanModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedEnvelopes, setSortedEnvelopes] = useState(envelopes);

  useEffect(() => {
    const filteredEnvelopes = envelopes.filter((envelope) =>
      (envelope.firstName + " " + envelope.lastName)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    const sortedData = filteredEnvelopes.sort(
      (a, b) => new Date(b.createdAt.seconds) - new Date(a.createdAt.seconds)
    );

    setSortedEnvelopes(sortedData);
  }, [envelopes, searchQuery]);

  const handleCloseMinyanModal = () => {
    setShowAddMinyanModal(false);
  };

  const toggleNameSelection = () => {
    setIsNameSelected(!isNameSelected);
  };

  const handleEnvelopeClick = (ind) => {
    if (!selectedEnvelope.includes(ind)) {
      setSelectedEnvelope([...selectedEnvelope, ind]);
      setMinyanCount(minyanCount + 1);
    } else {
      setSelectedEnvelope(selectedEnvelope.filter((item) => item !== ind));
      setMinyanCount(minyanCount - 1);
    }
  };

  const downloadData = () => {
    const header = ["Name", "School", "Grade", "Prizes Won", "Member Since"];

    const csvData = envelopes.map((envelope) => [
      envelope.firstName + " " + envelope.lastName,
      envelope.Yeshiava,
      envelope.grade,
      envelope.winnerList?.length || 0,
      formatDate(envelope.createdAt),
    ]);

    csvData.unshift(header);

    const csvRows = [];
    for (const row of csvData) {
      csvRows.push(row.join(","));
    }

    const csvContent = csvRows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "envelopes.csv");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-[98%] h-[92vh] mx-auto mt-[4vh] rounded-[15px] shadow-[0px_3.5px_5.5px_0px_rgba(0,0,0,0.05)] px-4 sm:px-6 md:px-[40px] pt-6 md:pt-[40px] gap-4 md:gap-[41px]">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between px-2 sm:px-[11px]">
          <div
            style={{ ...theme.typography.tableHeading }}
            className="mb-4 sm:mb-0"
          >
            {isNameSelected ? "New Envelope Requests" : "Envelopes"}
          </div>
          <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-4 sm:gap-[20px]">
            {isNameSelected && (
              <button
                className={`w-full sm:w-[198px] h-[39px] rounded-[15px] ${
                  minyanCount === 0
                    ? "bg-[#CCCCCC] cursor-not-allowed"
                    : "bg-[#4FD1C5]"
                } text-[#FFFFFF] text-[14px] font-bold flex items-center justify-center border-[0.5px] border-[#E2E8F0] gap-[11px]`}
                style={{ padding: "0px 19px 0px 16px" }}
                onClick={() => setShowAddMinyanModal(true)}
                disabled={minyanCount === 0}
              >
                <span
                  className="w-[22px] h-[22px] text-white rounded-full flex items-center justify-center border-[#FFFFFF] border-[1.5px]"
                  style={{ fontSize: "12px" }}
                >
                  {minyanCount}
                </span>
                <span>Add Minyan Points</span>
              </button>
            )}
            <div className="flex flex-row w-full sm:w-auto gap-4 sm:gap-[20px]">
              <SearchBar
                placeholder="Search in List"
                className="flex-grow sm:flex-grow-0"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <DownloadButton onClick={downloadData} />
            </div>
          </div>
        </div>

        <div className="overflow-y-auto custom-scrollbar">
          <EnvelopeTable
            isNameSelected={isNameSelected}
            toggleNameSelection={toggleNameSelection}
            handleEnvelopeClick={handleEnvelopeClick}
            selectedEnvelope={selectedEnvelope}
            envelopes={sortedEnvelopes}
          />
        </div>
      </div>
      <Modal isVisible={showAddMinyanModal} onClose={handleCloseMinyanModal}>
        <AddMinyanContent
          onClose={handleCloseMinyanModal}
          selectedEnvelope={selectedEnvelope}
          setSelectedEnvelope={setSelectedEnvelope}
          setMinyanCount={setMinyanCount}
        />
      </Modal>
    </>
  );
};

export default Envelopes;
