import React from "react";
import { theme } from "../../theme";
import toast from "react-hot-toast";
import { deleteDoc, doc } from "firebase/firestore";
import { useData } from "../../context/data";
import { db } from "../../db/firebase";

function DeleteAliyaModalContent(props) {
  return (
    <div className="flex flex-col justify-around items-center min-h-[170px] p-4 sm:p-6">
      <h2
        className="text-lg sm:text-xl md:text-2xl text-center mb-3 sm:mb-4"
        style={theme.dashboard.deleteModalHeading}
      >
        Delete Miniyan{" "}
        {props.requestType === "envelope" ? " Envelope" : " Request"}
      </h2>
      <p
        className="text-sm sm:text-base text-center mb-4 sm:mb-6"
        style={theme.dashboard.deleteModalSubHeading}
      >
        Are you sure you want to remove {props.name}'s Minyan{" "}
        {props.requestType === "envelope" ? "Envelope" : "Request"}?
      </p>
      <button
        className="px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base rounded"
        style={{
          ...theme.dashboard.outlinedButton,
          width: "fit-content",
          minWidth: "120px",
        }}
        onClick={props.handleDelete}
      >
        Delete
      </button>
    </div>
  );
}

export default DeleteAliyaModalContent;
