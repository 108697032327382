export const day = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  { label: "31", value: 31 },
];

export const requestAliyaType = [
  { label: "כהן", value: "Cohen" },
  { label: "לוי", value: "Levi" },
  { label: "ישראל", value: "Israel" },
];

export const weeksData = [
  { id: 1, name: "Bereshit" },
  { id: 2, name: "Vayechi" },
  { id: 3, name: "Pekudei" },
  { id: 4, name: "Bamidbar" },
  { id: 5, name: "Vaeschanan" },
  { id: 6, name: "Noach" },
  { id: 7, name: "Shemos" },
  { id: 8, name: "Vayikra" },
  { id: 9, name: "Naso" },
  { id: 10, name: "Eikev" },
  { id: 11, name: "Lech Lecha" },
  { id: 12, name: "Vaera" },
  { id: 13, name: "Tzav" },
  { id: 14, name: "Behaaloscha" },
  { id: 15, name: "Reeh" },
  { id: 16, name: "Vayera" },
  { id: 17, name: "Bo" },
  { id: 18, name: "Shimini" },
  { id: 19, name: "Shiach" },
  { id: 20, name: "Shoftim" },
  { id: 21, name: "Chayei Sarah" },
  { id: 22, name: "Beshalech" },
  { id: 23, name: "Tazria" },
  { id: 24, name: "Korach" },
  { id: 25, name: "Ki Seizei" },
  { id: 26, name: "Toldos" },
  { id: 27, name: "Yisro" },
  { id: 28, name: "Metzorah" },
  { id: 29, name: "Chukas" },
  { id: 30, name: "Ki Savo" },
  { id: 31, name: "Vayeitzei" },
  { id: 32, name: "Mishpatim" },
  { id: 33, name: "Acharei Mos" },
  { id: 34, name: "Balak" },
  { id: 35, name: "Netzavin" },
  { id: 36, name: "Vayishlach" },
  { id: 37, name: "Terumah" },
  { id: 38, name: "Kedoshim" },
  { id: 39, name: "Pinchas" },
  { id: 40, name: "Vayeilech" },
  { id: 41, name: "Vayeishev" },
  { id: 42, name: "Tetzavah" },
  { id: 43, name: "Emor" },
  { id: 44, name: "Matos" },
  { id: 45, name: "Haazinu" },
  { id: 46, name: "Miketz" },
  { id: 47, name: "Ki Sisa" },
  { id: 48, name: "Behar" },
  { id: 49, name: "Masei" },
  { id: 50, name: "Vzos Habracha" },
  { id: 51, name: "Vayigash" },
  { id: 52, name: "Vayakhel" },
  { id: 53, name: "Bechukosai" },
  { id: 54, name: "Devarim" },
];

export const weeksDropdownData = [
  { label: "Bereshit", value: 1 },
  { label: "Vayechi", value: 2 },
  { label: "Pekudei", value: 3 },
  { label: "Bamidbar", value: 4 },
  { label: "Vaeschanan", value: 5 },
  { label: "Noach", value: 6 },
  { label: "Shemos", value: 7 },
  { label: "Vayikra", value: 8 },
  { label: "Naso", value: 9 },
  { label: "Eikev", value: 10 },
  { label: "Lech Lecha", value: 11 },
  { label: "Vaera", value: 12 },
  { label: "Tzav", value: 13 },
  { label: "Behaaloscha", value: 14 },
  { label: "Reeh", value: 15 },
  { label: "Vayera", value: 16 },
  { label: "Bo", value: 17 },
  { label: "Shimini", value: 18 },
  { label: "Shiach", value: 19 },
  { label: "Shoftim", value: 20 },
  { label: "Chayei Sarah", value: 21 },
  { label: "Beshalech", value: 22 },
  { label: "Tazria", value: 23 },
  { label: "Korach", value: 24 },
  { label: "Ki Seizei", value: 25 },
  { label: "Toldos", value: 26 },
  { label: "Yisro", value: 27 },
  { label: "Metzorah", value: 28 },
  { label: "Chukas", value: 29 },
  { label: "Ki Savo", value: 30 },
  { label: "Vayeitzei", value: 31 },
  { label: "Mishpatim", value: 32 },
  { label: "Acharei Mos", value: 33 },
  { label: "Balak", value: 34 },
  { label: "Netzavin", value: 35 },
  { label: "Vayishlach", value: 36 },
  { label: "Terumah", value: 37 },
  { label: "Kedoshim", value: 38 },
  { label: "Pinchas", value: 39 },
  { label: "Vayeilech", value: 40 },
  { label: "Vayeishev", value: 41 },
  { label: "Tetzavah", value: 42 },
  { label: "Emor", value: 43 },
  { label: "Matos", value: 44 },
  { label: "Haazinu", value: 45 },
  { label: "Miketz", value: 46 },
  { label: "Ki Sisa", value: 47 },
  { label: "Behar", value: 48 },
  { label: "Masei", value: 49 },
  { label: "Vzos Habracha", value: 50 },
  { label: "Vayigash", value: 51 },
  { label: "Vayakhel", value: 52 },
  { label: "Bechukosai", value: 53 },
  { label: "Devarim", value: 54 },
];

export const weeksDropdownData2 = [
  { label: "Bereshit", value: "Bereshit" },
  { label: "Noach", value: "Noach" },
  { label: "Lech Lecha", value: "Lech Lecha" },
  { label: "Vayera", value: "Vayera" },
  { label: "Chayei Sarah", value: "Chayei Sarah" },
  { label: "Toldos", value: "Toldos" },
  { label: "Vayeitzei", value: "Vayeitzei" },
  { label: "Vayishlach", value: "Vayishlach" },
  { label: "Vayeishev", value: "Vayeishev" },
  { label: "Miketz", value: "Miketz" },
  { label: "Vayigash", value: "Vayigash" },
  { label: "Vayechi", value: "Vayechi" },
  { label: "Shemos", value: "Shemos" },
  { label: "Vaera", value: "Vaera" },
  { label: "Bo", value: "Bo" },
  { label: "Beshalech", value: "Beshalech" },
  { label: "Yisro", value: "Yisro" },
  { label: "Mishpatim", value: "Mishpatim" },
  { label: "Terumah", value: "Terumah" },
  { label: "Tetzavah", value: "Tetzavah" },
  { label: "Ki Sisa", value: "Ki Sisa" },
  { label: "Vayakhel", value: "Vayakhel" },
  { label: "Pekudei", value: "Pekudei" },
  { label: "Vayikra", value: "Vayikra" },
  { label: "Tzav", value: "Tzav" },
  { label: "Shemini", value: "Shemini" },
  { label: "Tazria", value: "Tazria" },
  { label: "Metzorah", value: "Metzorah" },
  { label: "Acharei Mos", value: "Acharei Mos" },
  { label: "Kedoshim", value: "Kedoshim" },
  { label: "Emor", value: "Emor" },
  { label: "Behar", value: "Behar" },
  { label: "Bechukosai", value: "Bechukosai" },
  { label: "Bamidbar", value: "Bamidbar" },
  { label: "Naso", value: "Naso" },
  { label: "Behaaloscha", value: "Behaaloscha" },
  { label: "Shlach", value: "Shlach" },
  { label: "Korach", value: "Korach" },
  { label: "Chukas", value: "Chukas" },
  { label: "Balak", value: "Balak" },
  { label: "Pinchas", value: "Pinchas" },
  { label: "Matos", value: "Matos" },
  { label: "Masei", value: "Masei" },
  { label: "Devarim", value: "Devarim" },
  { label: "Vaeschanan", value: "Vaeschanan" },
  { label: "Eikev", value: "Eikev" },
  { label: "Reeh", value: "Reeh" },
  { label: "Shoftim", value: "Shoftim" },
  { label: "Ki Seizei", value: "Ki Seizei" },
  { label: "Ki Savo", value: "Ki Savo" },
  { label: "Netzavim", value: "Netzavim" },
  { label: "Vayeilech", value: "Vayeilech" },
  { label: "Haazinu", value: "Haazinu" },
  { label: "Vzos Habracha", value: "Vzos Habracha" },
];

export const gradeData = [
  { label: "1st", value: "1st" },
  { label: "2nd", value: "2nd" },
  { label: "3rd", value: "3rd" },
  { label: "4th", value: "4th" },
  { label: "5th", value: "5th" },
  { label: "6th", value: "6th" },
  { label: "7th", value: "7th" },
  { label: "8th", value: "8th" },
];
