import React, { memo, useState } from "react";
import { theme } from "../../theme";
import InputField from "../common/InputField";
import CancelButton from "../common/CancelButton";
import CommonButton from "../common/CommonButton";
import { arrayUnion, doc, increment, updateDoc } from "firebase/firestore";
import { db } from "../../db/firebase";
import toast from "react-hot-toast";

const AddMinyanContent = ({
  onClose,
  selectedEnvelope,
  setSelectedEnvelope,
  setMinyanCount
}) => {
  const [minyanPoints, setMinyanPoints] = useState({
    amount: "",
    memo: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const points = parseInt(minyanPoints.amount, 10);
    if (isNaN(points) || points <= 0) {
      toast.error("Please enter a valid number of points");
      return;
    }

    const newMinyanPoints = {
      points: points,
      method: minyanPoints.memo,
      type: "add",
      timeStamp: new Date().toISOString(),
    };

    try {
      toast.loading("Adding Minyan Points...");

      for (const envelopeId of selectedEnvelope) {
        const envelopeDocRef = doc(db, "envelopes", envelopeId);
        await updateDoc(envelopeDocRef, {
          minyanPoints: increment(points),
          minyanPointsHistory: arrayUnion(newMinyanPoints),
        });
      }

      setSelectedEnvelope([]);
      setMinyanCount(0);
      toast.dismiss();
      toast.success("Minyan Points Added Successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMinyanPoints((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div
      className="flex flex-col justify-center items-center gap-[20px] mb-[20px]"
      onSubmit={handleSubmit}
    >
      <div className="mb-[7px]" style={theme.typography.modalTitle}>
        Add Minyan Points to all Selected boys
      </div>

      <div className="flex flex-row gap-[19px]">
        <div className="w-[134px]">
          <InputField
            type="number"
            label="Amount"
            name="amount"
            value={minyanPoints.amount}
            onChange={handleInputChange}
          />
        </div>
        <InputField
          label="Memo"
          name="memo"
          value={minyanPoints.memo}
          onChange={handleInputChange}
        />
      </div>

      <div className="flex flex-row gap-[14px]">
        <CancelButton name="cancel" onClick={onClose} />
        <CommonButton name="Add" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default AddMinyanContent;
