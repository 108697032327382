import React, { useState } from "react";
import { theme } from "../../theme";
import IconWithName from "./EditDeleteChip";
import { MdDelete, MdEdit } from "react-icons/md";
import Modal from "./Modal";
import RemoveWinnerModalContent from "../modalContent/RemoveWinnerModalContent";
import SelectWinnerModalContent from "../modalContent/SelectWinnerModalContent";

function WinnerCard({ parsha, prize, img, studentId, onRemoveWinner, handleEditWinner , prizeId}) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  return (
    <>
      <div className="w-full max-w-[380px] flex items-center p-3 space-x-2 sm:space-x-4 bg-[#F8F8F8] rounded-[17px] pl-8">
        {/* <div className="h-[80px] w-[60px] sm:h-[110px] sm:w-[80px] rounded-xl flex-shrink-0">
          <img
            src={img}
            alt="winnerBoy"
            className="w-full h-full object-cover rounded-xl"
          />
        </div> */}
        <div className="flex flex-col justify-between h-full w-full">
          <div className="flex justify-end space-x-2 sm:space-x-4 mb-2">
            <IconWithName
              Icon={MdEdit}
              name="EDIT"
              color="#2D3748"
              onClick={() => setIsEditModalVisible(true)}
            />
            <IconWithName
              Icon={MdDelete}
              name="REMOVE"
              color="#E53E3E"
              onClick={() => setIsDeleteModalVisible(true)}
            />
          </div>
          <div
            className="text-sm sm:text-base"
            style={theme.dashboard.aliyaCardSemiBoldText}
          >
            Parsha:{" "}
            <span style={theme.dashboard.aliyaCardBoldText}>{parsha}</span>
          </div>
          <div
            className="text-sm sm:text-base mb-1"
            style={theme.dashboard.aliyaCardSemiBoldText}
          >
            Prize:{" "}
            <span style={theme.dashboard.aliyaCardBoldText}>{prize}</span>
          </div>
        </div>
      </div>
      <Modal isVisible={isDeleteModalVisible} onClose={handleCloseDeleteModal}>
        <RemoveWinnerModalContent
          onClose={handleCloseDeleteModal}
          img={img}
          parsha={parsha}
          prize={prize}
          onRemoveWinner={onRemoveWinner}
          studentId={studentId}
        />
      </Modal>
      <Modal
        isVisible={isEditModalVisible}
        onClose={handleCloseEditModal}
      >
        <SelectWinnerModalContent week={parsha} isUpdate={true} handleEditWinner={handleEditWinner} prizeId= {prizeId} onClose={handleCloseEditModal}/>
      </Modal>
    </>
  );
}

export default WinnerCard;
