import React  from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ViewDetailButton = ({Item, envelopeId}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleViewDetail = () => {
    if(location.pathname === "/admin/envelopes") {
      navigate(`/admin/envelopes/profile/${envelopeId}`);
    }
    else if(location.pathname === "/admin/dashboard") {
      navigate(`/admin/dashboard/profile/${envelopeId}`);
    }
  }

  return (
    <button
      style={{
        width: "109px",
        height: "24px",
        padding: "0px 8px",
        gap: "0px",
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 5.5px 0px #0000000F",
        border: "none",
        cursor: "pointer",
        fontFamily: "Helvetica",
        fontWeight: 700,
        fontSize: "10px",
        lineHeight: "15px",
        textAlign: "center",
        color: "#2D3748",
      }}
      onClick={handleViewDetail}
    >
    {
      Item? Item : "View Detail"
    }
    </button>
  );
};

export default ViewDetailButton;
