import React from "react";
import WinnerCard from "./WinnerCard";

const GridView = ({ winnerList, onRemoveWinner, handleEditWinner }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2   gap-4 sm:gap-6 md:gap-8 custom-scrollbar overflow-y-auto pr-3">
      {winnerList.map((data, index) => (
        <div key={index} className="flex justify-center">
          <WinnerCard
            studentId={data.studentId}
            parsha={data.weekName}
            prize={data.prizeName}
            prizeId={data.prizeId}
            img={data.winnerImg}
            onRemoveWinner={onRemoveWinner}
            handleEditWinner={handleEditWinner}
          />
        </div>
      ))}
    </div>
  );
};

export default GridView;
