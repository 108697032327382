
import React from "react";
import { theme } from "../../theme";

function WeekChip({ id, name, isSelected, onClick }) {
  const handleClick = () => {
    onClick(id);
  };

  const chipStyle = isSelected
    ? theme.prizes.weekChipSelectedContainer
    : theme.prizes.weekChipNormalContainer;

  return (
    <button style={chipStyle} onClick={handleClick}>
      <span style={theme.prizes.weekChipText}>{name}</span>
    </button>
  );
}

export default WeekChip;
