import React, { useState } from "react";
import { theme } from "../theme";
import { FaRegCircle, FaRegCircleCheck } from "react-icons/fa6";
import { MdKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { sortBy } from "../data/envelopeData";
import { FaCheckCircle } from "react-icons/fa";
import ViewDetailButton from "./common/ViewDetails";
import Chip from "./common/Chip";
import DropdownList from "./common/DropDownList";
import { useData } from "../context/data";
import { formatDate } from "../utils";

const EvelopeTable = ({
  isNameSelected,
  toggleNameSelection,
  handleEnvelopeClick,
  selectedEnvelope,
  envelopes,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const handleFilterToggle = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const columns = [
    "Name",
    "School",
    "Grade",
    "Prizes Won",
    "Member Since",
    "Activity",
    "Filter",
  ];

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse min-w-[600px]">
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} className="hidden sm:table-cell" />
          <col style={{ width: "10%" }} className="hidden lg:table-cell" />
          <col style={{ width: "10%" }} className="hidden lg:table-cell" />
          <col style={{ width: "10%" }} className="lg:w-[10%]" />
        </colgroup>
        <thead>
          <tr
            style={{
              position: "sticky",
              top: "0px",
              verticalAlign: "middle",
              backgroundColor: "#FFFFFF",
            }}
          >
            {columns.map((column, index) => (
              <th
                key={index}
                style={{
                  ...theme.typography.tableColumnHeading,
                  borderBottom: "1px solid #E2E8F0",
                }}
                className={`pb-[13px] ${
                  index === 3
                    ? "hidden sm:table-cell"
                    : index === 4
                    ? "hidden lg:table-cell"
                    : index === 5
                    ? "hidden lg:table-cell"
                    : ""
                }`}
              >
                {index === 0 ? (
                  <div
                    className="flex flex-row gap-[10px] items-center cursor-pointer"
                    onClick={toggleNameSelection}
                  >
                    <FaRegCircleCheck
                      style={{
                        border: "1.5px",
                        padding: "2px",
                        width: "19px",
                        height: "19px",
                        color: isNameSelected ? "#4FD1C5" : "#A0AEC0",
                      }}
                    />
                    <div style={{ ...theme.typography.tableColumnHeading }}>
                      {column}
                    </div>
                  </div>
                ) : index === 6 ? (
                  <div
                    className="relative flex flex-row items-center gap-[10px] hover:cursor-pointer"
                    onClick={handleFilterToggle}
                  >
                    <div style={{ ...theme.typography.tableColumnHeading }}>
                      {selectedFilter ? selectedFilter : column}
                    </div>
                    {isFilterOpen ? (
                      <MdKeyboardArrowUp className="w-6 h-6" color="#A0AEC0" />
                    ) : (
                      <MdOutlineKeyboardArrowDown
                        className="w-6 h-6"
                        color="#A0AEC0"
                      />
                    )}
                    {isFilterOpen && (
                      <DropdownList
                        items={sortBy}
                        isOpen={isFilterOpen}
                        onOptionSelect={handleFilterChange}
                      />
                    )}
                  </div>
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {envelopes.map((data, index) => (
            <tr
              key={index}
              className="h-[62px]"
              style={{
                ...theme.typography.tableContent,
                borderBottom:
                  index !== envelopes.length - 1 ? "1px solid #E2E8F0" : "none",
              }}
            >
              <td
                className="flex flex-row gap-[10px] items-center"
                style={{
                  height: "inherit",
                  cursor: isNameSelected ? "pointer" : "text",
                }}
                onClick={() => handleEnvelopeClick(data.envelopeId)}
              >
                <span>
                  {isNameSelected ? (
                    selectedEnvelope.includes(data.envelopeId) ? (
                      <FaCheckCircle color="#4FD1C5" />
                    ) : (
                      <FaRegCircle color="#E2E8F0" />
                    )
                  ) : (
                    <div className="w-[14px] h-[14px] text-white rounded-full flex items-center justify-center border-[#FFFFFF] border-[1.5px]" />
                  )}
                </span>
                {data?.firstName + " " + data?.lastName}
              </td>
              <td>{data?.Yeshiava}</td>
              <td>{data.grade}</td>
              <td className="hidden sm:table-cell">
                {data?.winnerList?.length || 0}
              </td>
              <td className="hidden lg:table-cell">
                {formatDate(data?.createdAt)}
              </td>
              <td className="hidden lg:table-cell">
                <Chip isActive={data?.isActive} />
              </td>
              <td>
                <ViewDetailButton envelopeId={data.envelopeId} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EvelopeTable;
