import React, { useState } from "react";
import SelectField from "../common/SelectField2";
import InputField from "../common/InputField2";
import CButton from "../common/CButton";
import { theme } from "../../theme";
import { requestAliyaType, weeksDropdownData2 } from "../../data";
import RadioField from "../common/RadioField";
import { useData } from "../../context/data";
import { db } from "../../db/firebase";
import toast from "react-hot-toast";
import { doc, Timestamp, updateDoc } from "firebase/firestore";

const EditAliyaContent = ({
  onClose,
  name,
  fatherName,
  parshas,
  inHonorOf,
  nickName,
  type,
  requestId,
}) => {
  const [aliya, setAliya] = useState({
    name: name,
    fatherName: fatherName,
    nickName: nickName,
    parshas: parshas,
    inHonorOf: inHonorOf,
    type: type,
  });
  const { setRequests } = useData();

  const onInputChange = (e) => {
    setAliya({
      ...aliya,
      [e.target.id]: e.target.value,
    });
  };

  const onSelectChange = (id, value) => {
    setAliya({
      ...aliya,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toast.loading("Updating Aliya request...");

      const updatedRequests = { ...aliya, createdAt: Timestamp.now() };

      const requestDocRef = doc(db, "requests", requestId);
      await updateDoc(requestDocRef, updatedRequests);

      setRequests((prev) =>
        prev.map((request) =>
          request.requestId === requestId
            ? { ...request, ...updatedRequests }
            : request
        )
      );

      toast.dismiss();
      toast.success("Aliya request updated successfully");
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to update Aliya request");
      console.log("Error updating Aliya request", error);
    } finally {
      onClose();
    }
  };

  const onRadioChange = (e) => {
    setAliya({
      ...aliya,
      type: e.target.value,
    });
  };

  return (
    <form
      className="flex flex-col justify-center items-center w-full max-w-[600px] mx-auto px-4"
      onSubmit={handleSubmit}
    >
      <div
        className="mb-4 sm:mb-6 text-center"
        style={{ ...theme.typography.title, color: "#4FD1C5" }}
      >
        Edit Aliya Requests
      </div>

      <div className="flex flex-col space-y-6 sm:space-y-8 items-center w-full">
        <div className="flex flex-col md:flex-row md:space-x-4 w-full">
          <div className="flex flex-col space-y-4 w-full md:w-1/2">
            <InputField
              label="שם"
              placeholder="Type here"
              id="name"
              value={aliya.name}
              onChange={onInputChange}
            />

            <InputField
              label="בן"
              placeholder="Type here"
              id="fatherName"
              value={aliya.fatherName}
              onChange={onInputChange}
            />

            <InputField
              label="שם המשפחה"
              placeholder="Type here"
              id="nickName"
              value={aliya.nickName}
              onChange={onInputChange}
            />
          </div>

          <div className="flex flex-col space-y-4 w-full md:w-1/2 mt-4 md:mt-0">
            <SelectField
              label="Parsha's"
              items={weeksDropdownData2}
              onChange={onSelectChange}
              id="parshas"
              value={aliya.parshas}
            />

            <InputField
              label="In Honor of"
              placeholder="Type here"
              id="inHonorOf"
              value={aliya.inHonorOf}
              onChange={onInputChange}
            />

            <div className="flex justify-center items-center h-full pt-4 sm:pt-7">
              <RadioField
                options={requestAliyaType}
                onChange={onRadioChange}
                checked={aliya.type}
              />
            </div>
          </div>
        </div>

        <CButton name="Save" className="w-full sm:w-auto" />
      </div>
    </form>
  );
};

export default EditAliyaContent;
