import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import { theme } from "../theme";
import SchoolChip from "../components/common/SchoolChip";
import Modal from "../components/common/Modal";
import AddEditSchoolModalContent from "../components/modalContent/addEditSchoolModalContent";
import { useData } from "../context/data";

function Setting() {
  const [isAddSchoolModalOpen, setIsAddSchoolModalOpen] = useState(false);
  const [isEditSchoolModalOpen, setIsEditSchoolModalOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const { schools, setSchools } = useData();

  const handleCloseAddSchoolModal = () => {
    setIsAddSchoolModalOpen(false);
  };

  const handleCloseEditSchoolModal = () => {
    setIsEditSchoolModalOpen(false);
  };

  return (
    <>
      <div
        className="w-[98%] mr-[1%] bg-white rounded-[15px] mt-[4vh] px-12 py-6 flex flex-col gap-[10px]"
        style={{ boxShadow: "0px 3.5px 5.5px 0px #00000005" }}
      >
        <div style={theme.setting.heading}>Schools</div>
        <div className="flex-box h-[100%] space-x-4">
          {schools.map((school) => (
            <SchoolChip
              key={school.schoolId}
              name={school.name}
              backgroundColor={"#F2F2F2"}
              color={"#2D3748"}
              onClick={() => {
                setSelectedSchool(school);
                setIsEditSchoolModalOpen(true);
              }}
            />
          ))}

          <SchoolChip
            name={"Add School"}
            backgroundColor={"#4FD1C5"}
            color={"#FFFFFF"}
            onClick={() => setIsAddSchoolModalOpen(true)}
          />
        </div>
      </div>

      <Modal
        isVisible={isAddSchoolModalOpen}
        onClose={handleCloseAddSchoolModal}
      >
        <AddEditSchoolModalContent
          onClose={handleCloseAddSchoolModal}
          schools={schools}
          setSchools={setSchools}
        />
      </Modal>
      <Modal
        isVisible={isEditSchoolModalOpen}
        onClose={handleCloseEditSchoolModal}
      >
        <AddEditSchoolModalContent
          onClose={handleCloseEditSchoolModal}
          selectedSchool={selectedSchool}
          schools={schools}
          setSchools={setSchools}
        />
      </Modal>
    </>
  );
}

export default Setting;
