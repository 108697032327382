import React, { useState } from "react";
import { theme } from "../../theme";

export const MenuItem = ({ SelectedIcon, name, isSelected, onClick }) => {
  return (
    <div
      className="flex h-[54px] items-center mb-2 cursor-pointer hover:bg-[#CCCCCC] rounded-[16px]"
      style={isSelected === name ? theme.dashboard.selectedLogoContainer : {}}
      onClick={onClick}
    >
      <div className="px-4">
        <div
          className="flex justify-center items-center"
          style={
            isSelected === name
              ? theme.dashboard.selectedLogo
              : theme.dashboard.deselectLogo
          }
        >
          {isSelected === name ? (
            <SelectedIcon size={18} color="#ffffff" />
          ) : (
            <SelectedIcon size={18} color="#4FD1C5" />
          )}
        </div>
      </div>
      <div
        style={
          isSelected === name
            ? theme.dashboard.selectedLogoText
            : theme.dashboard.unselectedLogoText
        }
      >
        {" "}
        {name}
      </div>
    </div>
  );
};
