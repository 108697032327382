import React, { useEffect, useState } from "react";
import { theme } from "../theme";
import PrizeCard from "../components/common/PrizeCard";
import Modal from "../components/common/Modal";
import WeekModalContent from "../components/modalContent/WeekModalContent";
import { v4 as uuidv4 } from "uuid";
import { uploadImage, deleteImage } from "../util";
import {
  updatePrizeCards,
  fetchPrizesByWeekName,
} from "../api/prizesAPI";
import { Spinner } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { useData } from "../context/data";

function Prizes() {
  const { week } = useData();
  const [prizeCards, setPrizeCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(week);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSelectedWeek(week);
  }, [week]);

  useEffect(() => {
    const fetchPrizes = async () => {
      setIsLoading(true);
      try {
        const prizesData = await fetchPrizesByWeekName(selectedWeek);

        if (prizesData) {
          const formattedPrizeCards = prizesData.map((prize, index) => ({
            id: index + 1,
            name: prize.prizeName || "",
            studentId: prize.studentId || "",
            imgUrl: prize.prizeImg,
            selectedFile: null,
            winnerImg: prize.winnerImg,
          }));
          setPrizeCards(formattedPrizeCards);
        } else {
          setPrizeCards([
            {
              id: 1,
              name: "",
              selectedFile: null,
              imgUrl: null,
              winnerImg: "",
              studentId: "",
            },
            {
              id: 2,
              name: "",
              selectedFile: null,
              imgUrl: null,
              winnerImg: "",
              studentId: "",
            },
            {
              id: 3,
              name: "",
              selectedFile: null,
              imgUrl: null,
              winnerImg: "",
              studentId: "",
            },
            {
              id: 4,
              name: "",
              selectedFile: null,
              imgUrl: null,
              winnerImg: "",
              studentId: "",
            },
          ]);
        }
      } catch (error) {
        console.error("Error fetching prize data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrizes();
  }, [selectedWeek]);

  useEffect(() => {
    const saveChanges = async () => {
      try {
        await updatePrizeCards(selectedWeek, prizeCards);
      } catch (error) {
        console.error("Error saving prize cards: ", error);
        toast.error("Error saving prize cards");
      }
    };

    const debounceTimer = setTimeout(saveChanges, 1000);

    return () => clearTimeout(debounceTimer);
  }, [prizeCards, selectedWeek]);

  const handleFileChange = async (id, file) => {
    try {
      toast.loading("Uploading image...");
      const path = `prizes/${uuidv4()}_${selectedWeek}_${file.name}`;
      const imgUrl = await uploadImage(file, path);
      setPrizeCards((prevCards) =>
        prevCards.map((card) =>
          card.id === id ? { ...card, imgUrl, selectedFile: file } : card
        )
      );
      toast.dismiss();
      toast.success("Image uploaded successfully");
    } catch (error) {
      toast.dismiss();
      console.error("Error uploading image: ", error);
      toast.error("Error uploading image");
    }
  };

  const handleDelete = async (id) => {
    try {
      toast.loading("Deleting image...");
      const path = prizeCards.find((card) => card.id === id).imgUrl;
      if (path) {
        await deleteImage(path);
      }
      setPrizeCards((prevCards) =>
        prevCards.map((card) =>
          card.id === id ? { ...card, imgUrl: "", selectedFile: null } : card
        )
      );
      toast.dismiss();
      toast.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image: ", error);
      toast.error("Error deleting image");
    }
  };

  const handleNameChange = (id, name) => {
    setPrizeCards((prevCards) =>
      prevCards.map((card) => (card.id === id ? { ...card, name } : card))
    );
  };

  const handleCardClick = (id) => {
    setSelectedCardId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-col bg-white w-[98%] h-[92vh] mx-auto mt-[4vh] rounded-[15px] shadow-[0px_3.5px_5.5px_0px_rgba(0,0,0,0.05)] px-4 sm:px-6 md:px-[40px] pt-6 md:pt-[40px] gap-4 md:gap-[15px] items-center">
        <div style={theme.prizes.heading} className="text-center">
          Parsha
        </div>
        <button
          style={{ ...theme.prizes.button, width: "100%", maxWidth: "300px" }}
          onClick={() => setIsModalOpen(true)}
          className="w-full sm:w-auto px-4 py-2 text-center"
        >
          {selectedWeek}
        </button>
        {isLoading ? (
          <div className="flex justify-center items-center h-[100%] w-[100%]">
            <Spinner />
          </div>
        ) : (
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-5 w-full">
            {prizeCards.map((card) => (
              <div key={card.id} className="h-[35vh] lg:h-[60vh]">
                <PrizeCard
                  id={card.id}
                  img={card.imgUrl}
                  name={card.name}
                  height={"100%"}
                  width={"100%"}
                  backgroundColor={"#F8F9FA"}
                  onFileChange={(file) => handleFileChange(card.id, file)}
                  onDelete={() => handleDelete(card.id)}
                  onNameChange={(name) => handleNameChange(card.id, name)}
                  onCardClick={() => handleCardClick(card.id)}
                  isSelected={""}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal isVisible={isModalOpen} onClose={handleCloseModal}>
        <WeekModalContent
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          onClose={handleCloseModal}
        />
      </Modal>
    </>
  );
}

export default Prizes;
