export const theme = {
  colors: {
    primaryText: "#2D3748",
    secondaryText: "#A0AEC0",
    primaryColor: "#4FD1C5",
    secondaryColor: "#FFFFFF",
    success: "#28a745",
    danger: "#dc3545",
    warning: "#ffc107",
    info: "#17a2b8",
    light: "#f8f9fa",
    dark: "#343a40",
    white: "#fff",
    black: "#000",
    backgroundPrimary: "#ffffff",
    popupBackground: "#F6FCFF",
    buttonTextUnselected: "5BC9FE",
    layoutBackground: "#F8F9FA",
  },
  typography: {
    tableHeading: {
      fontFamily: "Helvetica",
      fontWeight: 700,
      color: "#2D3748",
      fontSize: "18px",
      lineHeight: "25.2px",
    },
    tableColumnHeading: {
      fontFamily: "Helvetica",
      fontWeight: 700,
      color: "#A0AEC0",
      fontSize: "10px",
      lineHeight: "15px",
      textAlign: "left",
    },
    tableContent: {
      fontFamily: "Helvetica",
      fontWeight: 700,
      color: "#2D3748",
      fontSize: "14px",
      lineHeight: "19.6px",
    },
    heading: {
      fontFamily: "Fredoka",
      fontWeight: 500,
      color: "#597FA4",
      fontSize: "64px",
      lineHeight: "77.44px",
      Align: "center",
    },
    subheading: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#909090",
      fontSize: "36px",
      lineHeight: "44.66px",
      Align: "center",
    },
    title: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#5BC9FE",
      fontSize: "37px",
      lineHeight: "45.91px",
      Align: "center",
    },
    subheading2: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#909090",
      fontSize: "22px",
      lineHeight: "27.3px",
      Align: "center",
      textAlign: "center",
    },
    paragraph: {
      fontFamily: "Fresca",
      fontWeight: 400,
      color: "#597FA4",
      fontSize: "19px",
      lineHeight: "21.93px",
      Align: "center",
      textAlign: "center",
    },
    iconLabel: {
      fontFamily: "Roboto Condensed",
      fontWeight: 700,
      color: "#FFFFFF",
      fontSize: "16px",
      lineHeight: "18.75px",
      Align: "center",
      letterSpacing: "-1%",
    },
    inputLabel: {
      fontFamily: "Helvetica",
      fontWeight: 600,
      color: "#666666",
      fontSize: "12px",
      lineHeight: "18px",
    },
    placeholder: {
      fontFamily: "Noto Sans",
      fontWeight: 400,
      color: "#666666",
      fontSize: "16px",
      lineHeight: "24px",
    },
    primaryButtonText: {
      fontFamily: "Noto Sans",
      fontWeight: 700,
      color: "#FFFFFF",
      fontSize: "16px",
      lineHeight: "24px",
      Align: "center",
    },
    secondaryButtonText: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#5BC9FE",
      lineHeight: "40.94px",
      Align: "center",
    },
    selectedButtonText: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#FFFFFF",
      lineHeight: "40.94px",
      Align: "center",
    },
    gridHeadingText: {
      fontFamily: "Helvetica",
      fontWeight: 500,
      color: "#5BC9FE",
      fontSize: "25px",
      lineHeight: "31.02px",
    },
    gridSubHeadingText: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#909090",
      fontSize: "18px",
      lineHeight: "25.94px",
    },
    imageHeadingText: {
      fontFamily: "Helvetica",
      fontWeight: 500,
      color: "#5BC9FE",
      fontSize: "19px",
      lineHeight: "30.44px",
      Align: "center",
      letterSpacing: "-1%",
    },
    imageSubHeadingText: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#909090",
      fontSize: "14px",
      lineHeight: "22.43px",
    },
    paymentInputLabel: {
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#030102",
      fontSize: "16px",
      lineHeight: "18.75px",
    },
    paymentInputText: {
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#030102",
      fontSize: "16px",
      lineHeight: "18.75px",
    },
    paymentPlaceholder: {
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#BCC3D1",
      fontSize: "16px",
      lineHeight: "18.75px",
    },
    modalTitle: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#4FD1C5",
      fontSize: "22px",
      lineHeight: "27.3px",
      textAlign: "center",
    },
    modalSubTitle: {
      fontFamily: "Helvetica",
      fontWeight: 400,
      color: "#777777",
      fontSize: "16px",
      lineHeight: "19.85px",
      textAlign: "center",
    },
  },
  dashboard: {
    logoText: {
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "21px",
      textAlign: "left",
      color: "#2D3748",
    },
    selectedLogoContainer: {
      background: "#ffffff",
      boxShadow: "0px 3.5px 5.5px 0px #00000005",
      borderRadius: "16px",
    },
    selectedLogo: {
      width: "30px",
      height: "30px",
      borderRadius: "40%",
      background: "#4FD1C5",
    },
    deselectLogo: {
      width: "30px",
      height: "30px",
      borderRadius: "45%",
      background: "#ffffff",
    },
    selectedLogoText: {
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "18px",
      color: "#2D3748",
    },
    unselectedLogoText: {
      fontFamily: "Helvetica",
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "18px",
      color: "#A0AEC0",
    },
    headerCardContainer: {
      height: "90px",
      background: "#ffffff",
      boxShadow: "0px 3.5px 5.5px 0px #00000005",
      borderRadius: "16px",
    },
    headerCardCountText: {
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "25.2px",
      color: "#2D3748",
    },
    headerCardPercentageText: {
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "19.6px",
    },
    headerCardSubText: {
      fontFamily: "Helvetica",
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "14px",
      color: "#A0AEC0",
    },
    headerCardLogoContainer: {
      width: "50px",
      height: "50px",
      borderRadius: "12px",
      background: "#4FD1C5",
    },
    aliyaCardContainer: {
      width: "100%",
      background: "#F8F9FA",
      borderRadius: "12px",
      marginBottom: "10px",
      padding: "15px 25px",
    },
    aliyaCardBoldText: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "22.4px",
      color: "#718096",
    },
    aliyaCardSemiBoldText: {
      fontFamily: "Helvetica",
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "27.3px",
      color: "#A0AEC0",
    },
    aliyaCardThinText: {
      fontFamily: "Helvetica",
      fontSize: "11px",
      fontWeight: "300",
      lineHeight: "15.6px",
      color: "#718096",
    },
    deleteModalHeading: {
      fontFamily: "Helvetica",
      fontSize: "26px",
      fontWeight: "400",
      lineHeight: "32.28px",
      color: "#4FD1C5",
    },
    deleteModalSubHeading: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "20.14px",
      color: "#777777",
      textAlign: "center",
    },
    outlinedButton : {
      borderRadius: "8px",
      border: "2px solid #4FD1C5",
      background: "#ffffff",
      padding: "5px 20px",
      color: "#4FD1C5",
      fontFamily:"Helvetica",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "24px",
      textAlign: "center",
    },

  },
  prizes : {
    heading: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22.4px",
      color: "#717171",
    },
    button: {
      width: "150px",
      height: "58px",
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "22.4px",
      color: "#4FD1C5",
      borderRadius: "150px",
      padding: "0px 8px 0px 8px",
      background: "#ffffff",
      boxShadow:"0px 2px 5.5px 0px #0000000F",

    },
    prizeCardContainer: {
      borderRadius: "16px",
      padding: "5px",
    },
    imgContainer: {
      width: "100%",
      height: "80%",
      borderRadius: "16px",
    },
    prizeCardText: {
      fontFamily: "Inter",
      // fontSize: "22px",
      fontWeight: "700",
      lineHeight: "33px",
      color: "#383636",
      width: "70%",
      textAlign: "center",
      marginTop: "10px",
    },
    prizeCardUploadText: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22.4px",
      color: "#717171",
    },
    selectWinnerModalHeading: {
      fontFamily: "Helvetica",
      fontSize: "30px",
      fontWeight: "400",
      lineHeight: "37.2px",
      color: "#4FD1C5",
      textAlign: "center",
    },
    DropdownContainer: {
      width: "165px",
      height: "30px",
      // background: "#FFFFFF",
      // borderRadius: "12px",
      padding: "5px",
      // boxShadow:" 0px 2px 5.5px 0px #0000000F",

    },
    weekChipSelectedContainer: {
      width: "144px",
      height: "36px",
      borderRadius: "12px",
      padding: "0px 8px 0px 8px",
      boxShadow:" 0px 2px 5.5px 0px #0000000F",
      background: "#4FD1C5",
      color: "#FFFFFF",
    },

    weekChipNormalContainer: {
      width: "144px",
      height: "36px",
      borderRadius: "12px",
      padding: "0px 8px 0px 8px",
      boxShadow:" 0px 2px 5.5px 0px #0000000F",
      background: "#FFFFFF",
      color: "#4FD1C5",
    },

    weekChipText: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "25.2px",
      lineSpacing: "1%",
      textAlign: "center",
    },

  },

  setting : {
    heading: {
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "25.2px",
      color: "#2D3748",
    },
    button: {
      height: "40px",
      fontFamily: "Helvetica",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "22.4px",
      borderRadius: "100px",
      padding: "4px 20px 4px 20px",
      marginBottom: "15px",
    },
  },

  breakpoints: {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
};
