import React, { useState } from "react";
import { theme } from "../../theme";
import InputField from "../common/InputField";
import CommonButton from "../common/CommonButton";

const AddRemoveMinyanPointContent = ({
  onClose,
  handlePoints,
}) => {
  const [isAdd, setIsAdd] = useState(true);
  const [minyanPoints, setMinyanPoints] = useState({
    amount: "",
    memo: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const newMinyanPoints = {
      points: minyanPoints.amount,
      type: isAdd ? "add" : "remove",
      method: minyanPoints.memo,
      timeStamp: new Date().toISOString(),
    };
    handlePoints(newMinyanPoints);
    onClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMinyanPoints((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div
      className="flex flex-col justify-center items-center gap-[20px] mb-[20px] w-full px-4 sm:px-6 md:px-8"
      onSubmit={handleSubmit}
    >
      <div
        className="mb-[7px] text-center"
        style={{
          ...theme.typography.modalTitle,
          fontSize: "clamp(18px, 4vw, 24px)",
        }}
      >
        {isAdd ? "Add Minyan Points" : "Remove Minyan Points"}
      </div>

      <div className="flex flex-col sm:flex-row gap-[19px] w-full max-w-[600px]">
        <div className="w-full sm:w-[134px]">
          <InputField
            type="number"
            label="Amount"
            name="amount"
            value={minyanPoints.amount}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full">
          <InputField
            label="Memo"
            name="memo"
            value={minyanPoints.memo}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="flex flex-col items-center gap-[14px] w-full max-w-[600px]">
        <CommonButton
          name={isAdd ? "Add" : "Remove"}
          onClick={handleSubmit}
          className="w-full sm:w-[50%]"
        />
        <button
          className="text-[#B7B7B7] text-sm sm:text-base"
          style={{
            fontFamily: "Helvetica",
            lineHeight: "1.2",
            fontWeight: "400",
          }}
          onClick={() => setIsAdd(!isAdd)}
        >
          {isAdd ? "Remove Points" : "Add Points"}
        </button>
      </div>
    </div>
  );
};

export default AddRemoveMinyanPointContent;
