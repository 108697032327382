export const formatDate = (timestamp) => {
  const date = new Date(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  );

  const options = { month: "long", day: "numeric" };

  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const getSchoolName = (schoolId, schools) => {
  const school = schools.find((school) => school.schoolId === schoolId);
  return school ? school.name : "";
};
