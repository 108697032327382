import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { theme } from "../../theme";

const SelectField = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.value || null);

  const handleToggle = (e) => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (item) => () => {
    setSelectedOption(item.label);
    setIsOpen(false);
    props.onChange(props.id, item.value);
  };

  return (
    <div className="flex flex-col space-y-[8px]">
      <div style={theme.typography.inputLabel}>{props.label}</div>

      <div
        className="relative inline-flex w-full bg-white rounded-lg border border-[#CCCCCC] focus-within:border-2 focus-within:border-[#4FD1C5] h-14"
        style={{
          border: isOpen ? "2px solid #4FD1C5" : "1px solid #CCCCCC",
        }}
      >
        <div
          className="w-4/5 px-4 py-2 cursor-pointer flex items-center"
          onClick={handleToggle}
          style={{ color: selectedOption ? "#000000" : "#A0AEC0" }}
        >
          {selectedOption || "Select"}
        </div>

        <div
          className="absolute right-3 top-1/2 transform -translate-y-1/2 h-6 w-6 flex justify-center items-center cursor-pointer"
          onClick={handleToggle}
        >
          <IoIosArrowDown style={{ color: "#5BC9FE" }} />
        </div>

        {isOpen && (
          <div className="absolute top-full left-0 z-10 mt-1 w-full origin-top-right rounded-md bg-white max-h-60 overflow-y-auto custom-scrollbar">
            {props.items.map((item) => (
              <button
                type="button"
                onClick={onOptionClicked(item)}
                key={item.value}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-[#4FD1C5] hover:text-white focus:outline-none"
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectField;
