import React, { useEffect, useState } from "react";
import logoRebLev from "../../assets/svg/logoRebLev.svg";
import { theme } from "../../theme";
import { MenuItem } from "./leftLayoutComponents";
import { IoHome, IoMail, IoGift, IoSettings } from "react-icons/io5";
import { HiOutlineLogout } from "react-icons/hi";
import Modal from "../common/Modal";
import CancelButton from "../common/CancelButton";
import CommonButton from "../common/CommonButton";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../db/firebase";
import { signOut } from "firebase/auth";

const LeftLayout = ({ closeMobileMenu }) => {
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState();
  const location = useLocation();

  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const handleItemClick = (path) => {
    navigate(path);
    closeMobileMenu();
  };

  const handleLogout = async () => {
    try {
      toast.loading("Please wait...");
      await signOut(auth);
      toast.dismiss();
      toast.success("Logout successful!");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      toast.dismiss();
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLogoutModalOpen(false);
    }
  };

  const menuItems = [
    { name: "dashboard", path: "/admin/dashboard", icon: IoHome },
    { name: "envelopes", path: "/admin/envelopes", icon: IoMail },
    { name: "prizes", path: "/admin/prizes", icon: IoGift },
    { name: "setting", path: "/admin/setting", icon: IoSettings },
  ];

  useEffect(() => {
    const currentPath = location.pathname.split("/")[2];
    setIsSelected(currentPath);
  }, [location.pathname]);

  return (
    <>
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col items-center sm:items-start justify-center">
          <div className="flex items-center sm:flex-row flex-col">
            <div className="pr-0 sm:pr-4 h-10 w-10">
              <img src={logoRebLev} alt="logo" className="h-full w-full" />
            </div>
            <div
              className="mt-2 sm:mt-0 text-center sm:text-left"
              style={theme.dashboard.logoText}
            >
              Reb Levik's Minyan
            </div>
          </div>

          <div className="flex flex-col mt-2 sm:mt-8 w-[90%]">
            {menuItems.map((item) => (
              <MenuItem
                key={item.name}
                name={item.name}
                SelectedIcon={item.icon}
                onClick={() => handleItemClick(item.path)}
                isSelected={isSelected}
              />
            ))}
          </div>
        </div>
        <div className="mt-auto mb-12 sm:mb-4 ml-4 ">
          <button
            className="flex items-center text-gray-400 hover:text-gray-800 transition-colors"
            onClick={() => setIsLogoutModalOpen(true)}
          >
            <HiOutlineLogout className="mr-2" size={24} />
            <span
              style={{
                fontFamily: "Helvetica",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "21px",
                textAlign: "left",
              }}
            >
              Logout
            </span>
          </button>
        </div>
      </div>
      <Modal isVisible={isLogoutModalOpen} onClose={handleCloseLogoutModal}>
        <div
          className="flex flex-col items-center justify-center"
          style={{ height: "200px" }}
        >
          <div
            className="text-center"
            style={{
              ...theme.typography.modalTitle,
              fontSize: "clamp(18px, 4vw, 24px)",
            }}
          >
            Are you sure you want to logout?
          </div>
          <div className="flex flex-col sm:flex-row gap-[14px] w-full justify-center mt-5">
            <CancelButton
              name="Cancel"
              onClick={handleCloseLogoutModal}
              className="w-full sm:w-auto"
            />
            <CommonButton
              name={"Yes"}
              onClick={handleLogout}
              className="w-full sm:w-auto"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LeftLayout;
