import React from "react";

const DownloadButton = ({onClick}) => {
  return (
    <button
      className="
      w-[152px] 
      h-[39px] 
      rounded-[15px] 
      bg-white 
      border-[0.5px]
      border-[#E2E8F0]
      hover:bg-[#4FD1C5]
      hover:text-white
      text-[#A0AEC0]
    "
      style={{
        fontFamily: "Helvetica",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "19.6px",
        align: "center",
      }}
      onClick={onClick}
    >
      Download List
    </button>
  );
};

export default DownloadButton;
