import React, { useState, useEffect } from "react";
import WeekChip from "../common/weekChip";
import { weeksData } from "../../data";
import CommonButton from "../common/CommonButton";
import { Checkbox } from "@chakra-ui/react";
import { theme } from "../../theme";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../db/firebase";
import toast from "react-hot-toast";
import { useData } from "../../context/data";


function WeekModalContent({ setSelectedWeek, selectedWeek, onClose }) {
  const {setWeek} = useData();
  const [selectedChip, setSelectedChip] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const currentWeek = weeksData.find((week) => week.name === selectedWeek);
    if (currentWeek) {
      setSelectedChip(currentWeek.id);
    }
  }, [selectedWeek]);

  const handleChipClick = (id) => {
    setSelectedChip(id);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSave = async () => {
    if(selectedChip)
    {
      setSelectedWeek(weeksData.find((week) => week.id === selectedChip).name);
    }

    if (isChecked && selectedChip) {
      toast.loading("Updating selected week...");
      const selectedWeekData = weeksData.find(
        (week) => week.id === selectedChip
      );
      if (selectedWeekData) {
        setSelectedWeek(selectedWeekData.name);
      }

      try {
        const weekDocRef = doc(db, "common", "week");
        await updateDoc(weekDocRef, { selectedWeek: selectedWeekData.name });
        setWeek(selectedWeekData.name)
        toast.dismiss();
        toast.success("Selected week updated successfully");
      } catch (error) {
        toast.dismiss();
        toast.error("Error updating selected week");
        throw error;
      }
    }
    onClose();
  };

  return (
    <div className="flex flex-col w-full max-w-[780px] items-center gap-4 sm:gap-5 md:gap-6 lg:gap-7 xl:gap-8">
      <div className="flex flex-wrap justify-center gap-2 sm:gap-3 md:gap-4 w-full mt-[-15px] max-h-[600px] sm:h-full overflow-y-auto custom-scrollbar">
        {weeksData.map((week) => (
          <WeekChip
            key={week.id}
            id={week.id}
            name={week.name}
            isSelected={selectedChip === week.id}
            onClick={handleChipClick}
          />
        ))}
      </div>
      <div className="w-full max-w-[250px] sm:max-w-[300px] md:max-w-[350px] lg:max-w-[400px] xl:max-w-[450px] flex flex-col sm:flex-row justify-betweem sm:justify-center items-center gap-4 sm:gap-4">
        <div className="w-full sm:w-auto">
          <Checkbox
            colorScheme="cyan"
            size={"lg"}
            isChecked={isChecked}
            onChange={handleCheckboxChange}
          >
            <span
              className="text-sm sm:text-base md:text-lg lg:text-xl"
              style={theme.prizes.heading}
            >
              {" "}
              Set as this week
            </span>
          </Checkbox>
        </div>
        <CommonButton name="Save" onClick={handleSave} />
      </div>
    </div>
  );
}

export default WeekModalContent;
