import React from "react";
import { FaPlus } from "react-icons/fa";
import { IoMdRemove } from "react-icons/io";

const MinyanPointHistory = ({ history }) => {
  const sortedHistory = history?.sort(
    (a, b) => new Date(b.timeStamp) - new Date(a.timeStamp)
  );

  return (
    <>
      {sortedHistory?.map((item, index) => (
        <div
          key={index}
          className="flex flex-row items-center justify-between px-[11px] h-[36px] min-w-[220px] bg-[#FFFFFF] rounded-[100px] mb-[13px] w-full"
        >
          <div className="flex flex-row gap-[14px] sm:gap-12 md:gap-12 lg:gap-8 xl:gap-12">
            <div
              style={{
                fontFamily: "Helvetica",
                fontSize: "15px",
                lineHeight: "21px",
                fontWeight: "700",
              }}
            >
              {item.type === "add" ? (
                <div
                  className="flex flex-row items-center"
                  style={{
                    color: "#4FD1C5",
                  }}
                >
                  <FaPlus />
                  <div>{item.points}</div>
                </div>
              ) : (
                <div
                  className="flex flex-row items-center"
                  style={{
                    color: "#D16B4F",
                  }}
                >
                  <IoMdRemove />
                  <div>{item.points}</div>
                </div>
              )}
            </div>

            <div
              style={{
                fontFamily: "Helvetica",
                fontSize: "15px",
                lineHeight: "21px",
                fontWeight: "400",
                color: "#B8B8B8",
              }}
            >
              {item.method}
            </div>
          </div>

          <div
            style={{
              fontFamily: "Helvetica",
              fontSize: "12px",
              lineHeight: "16.8px",
              fontWeight: "300",
              color: "#B8B8B8",
            }}
          >
            {new Date(item.timeStamp).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export default MinyanPointHistory;

