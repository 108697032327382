import React from "react";

const DropdownList = (props) => {
  const onOptionClicked = (item) => {
    return () => {
      props.onOptionSelect(item.value);
    };
  };

  return (
    props.isOpen && (
      <div className="absolute top-full left-0 z-10 mt-1 w-[100px] origin-top-right rounded-md bg-[#F6FCFF] max-h-60 overflow-y-auto custom-scrollbar">
        {props.items.map((item) => (
          <button
            type="button"
            onClick={onOptionClicked(item)}
            key={item.value}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-[#4FD1C5] hover:text-[#FFFFFF] focus:outline-none"
          >
            {item.label}
          </button>
        ))}
      </div>
    )
  );
};

export default DropdownList;
