import React, { useState } from "react";
import { theme } from "../../theme/index";
import IconWithName from "./EditDeleteChip";
import { MdDelete, MdEdit } from "react-icons/md";
import Modal from "./Modal";
import DeleteAliyaModalContent from "../modalContent/DeleteAliyaModalContent";
import EditAliyaContent from "../modalContent/EditAliyaContent";
import { useData } from "../../context/data";
import { db } from "../../db/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import toast from "react-hot-toast";

function AliyaRequestCard({
  name,
  fatherName,
  parsha,
  honorOf,
  IamA,
  date,
  nickName,
  requestId,
}) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { setRequests } = useData();

  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleDelete = async () => {
    try {
      toast.loading("Deleting request...");

      const requestDocRef = doc(db, "requests", requestId);
      await deleteDoc(requestDocRef);

      setRequests((prev) =>
        prev.filter((request) => request.requestId !== requestId)
      );

      toast.dismiss();
      toast.success("Request deleted successfully");
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to delete request");
    }
  };

  return (
    <>
      <div
        className="flex flex-col justify-between p-4 mb-4 "
        style={{
          ...theme.dashboard.aliyaCardContainer,
        }}
      >
        <div className="flex flex-row justify-between items-center mb-2">
          <div
            style={theme.dashboard.aliyaCardBoldText}
            className="mb-2 sm:mb-0"
          >
            {name} <span style={theme.dashboard.aliyaCardThinText}>Ben</span>{" "}
            {fatherName}
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <div>
              <IconWithName
                Icon={MdDelete}
                name={"DELETE"}
                color="red"
                onClick={() => setIsDeleteModalVisible(true)}
              />
            </div>
            <div>
              <IconWithName
                Icon={MdEdit}
                name={"EDIT"}
                color="#2D3748"
                onClick={() => setIsEditModalVisible(true)}
              />
            </div>
          </div>
        </div>
        <div style={theme.dashboard.aliyaCardSemiBoldText} className="mb-1">
          Parsha:{" "}
          <span style={theme.dashboard.aliyaCardBoldText}>{parsha}</span>
        </div>
        <div style={theme.dashboard.aliyaCardSemiBoldText} className="mb-1">
          In honor of:{" "}
          <span style={theme.dashboard.aliyaCardBoldText}>{honorOf}</span>
        </div>
        <div style={theme.dashboard.aliyaCardSemiBoldText} className="mb-1">
          I am a: <span style={theme.dashboard.aliyaCardBoldText}>{IamA}</span>
        </div>
        <div style={theme.dashboard.aliyaCardSemiBoldText}>
          Date: <span style={theme.dashboard.aliyaCardBoldText}>{date}</span>
        </div>
      </div>
      <Modal isVisible={isDeleteModalVisible} onClose={handleCloseDeleteModal}>
        <DeleteAliyaModalContent
          handleDelete={handleDelete}
          name={name}
          requestType="request"
        />
      </Modal>
      <Modal isVisible={isEditModalVisible} onClose={handleCloseEditModal}>
        <EditAliyaContent
          onClose={handleCloseEditModal}
          name={name}
          fatherName={fatherName}
          parshas={parsha}
          inHonorOf={honorOf}
          nickName={nickName}
          type={IamA}
          requestId={requestId}
        />
      </Modal>
    </>
  );
}

export default AliyaRequestCard;
