import React from "react";

const CancelButton = (props) => {
  return (
    <button
      className="border-[2px] border-[#DCDCDC] rounded-lg px-4 h-[41px]"
      onClick={props.onClick}
    >
      <div
        style={{
          fontFamily: "Helvetica",
          fontWeight: 700,
          color: "#DCDCDC",
          lineHeight: "24px",
          textAlign: "center",
        }}
      >
        {props.name}
      </div>
    </button>
  );
};

export default CancelButton;
