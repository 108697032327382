import React, { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const InputLogin = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div
      className="flex flex-col gap-[5px] w-[350px] h-[60px]"
      style={{
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "15.06px",
      }}
    >
      <div>{props.label}</div>
      <div className="relative">
        <input
          type={isPasswordVisible ? "text" : props.type}
          placeholder={props.placeholder}
          className="h-[40px] rounded-[4px] p-[10px] text-[#333333] focus:outline-none w-full"
          onKeyDown={props.onKeyDown}
          style={{
            border: "1px solid #D8D8D8",
            backgroundColor: "#F8F8F8",
          }}
          id={props.id}
          value={props.value}
          onChange={props.onChange}
        />
        {props.type === "password" && (
          <div
            className="absolute right-[10px] top-1/2 transform -translate-y-1/2 cursor-pointer "
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? (
              <IoMdEye size={16} color="#333333" opacity={0.6} />
            ) : (
              <IoMdEyeOff size={16} color="#333333" opacity={0.6} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputLogin;
