import React from "react";

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === "wrapper") {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 bg-white bg-opacity-[68%] flex justify-center items-center"
      id="wrapper"
      onClick={handleClose}
    >
      <div className="mx-auto flex flex-col max-w-[90%] max-h-[90vh]">
        <div
          className="p-5"
          style={{
            backgroundColor: "#F8F9FA",
            borderRadius: "30px",
            maxWidth: "100%",
            minwidth: "300px",
          }}
        >
          <div className="flex justify-end pt-[2%] pr-[3%]">
            <button
              className="text-xl place-self-end font-bold"
              style={{
                color: "#BDBDBD",
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <div className="px-16">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
