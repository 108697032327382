import React, { useEffect, useState } from "react";
import EditableName from "../components/common/EditableName";
import { theme } from "../theme";
import { Spinner, Switch } from "@chakra-ui/react";
import SelectField3 from "../components/common/SelectField3";
import { gradeData } from "../data";
import InputField3 from "../components/common/InputField3";
import { FaPlus } from "react-icons/fa";
import MinyanPointHistory from "../components/common/MinyanPointHistory";
import { IoMdRemove } from "react-icons/io";
import GridView from "../components/common/GridView";
import AddRemoveMinyanPointContent from "../components/modalContent/AddRemoveMinyanPointContent";
import Modal from "../components/common/Modal";
import SelectWinnerModalContent from "../components/modalContent/SelectWinnerModalContent";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../db/firebase";
import { IoMdArrowRoundBack } from "react-icons/io";
import FieldsWithName from "../components/common/fieldsWithName";
import person from "../assets/png/person.png";
import {
  arrayRemove,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useData } from "../context/data";
import toast from "react-hot-toast";
import DeleteAliyaModalContent from "../components/modalContent/DeleteAliyaModalContent";
import { findWeekIdByName } from "../api/winnersAPI";
import { deleteImage } from "../util";

const Profile = () => {
  const { setEnvelopes, week, schools } = useData();
  const { envelopeId } = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [buyPoints, setBuyPoints] = useState(10);
  const [profileData, setProfileData] = useState({});
  const [loading, setloading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [imgUrl, setImageUrl] = useState(person);

  useEffect(() => {
    if (profileData.studentImgUrl) {
      setImageUrl(profileData.studentImgUrl);
    }
  }, [profileData.studentImgUrl]);

  const formattedSchools = schools.map((school) => {
    return { label: school.name, value: school.name };
  });

  const fetchEnvelopeData = async () => {
    try {
      setloading(true);
      const envelopeRef = doc(db, "envelopes", envelopeId);
      const envelopeSnapshot = await getDoc(envelopeRef);
      const envelopeData = envelopeSnapshot.data();
      setProfileData({
        name: `${envelopeData?.firstName || ""} ${
          envelopeData?.lastName || ""
        }`.trim(),
        Yeshiava: envelopeData?.Yeshiava || "ULY",
        parentEmail: envelopeData?.parentEmail || "test@gmail.com",
        birthDate:
          envelopeData?.birthDay + " " + envelopeData?.birthMonth || "21 Elul",
        rebbe: envelopeData?.rebbe || "Rabbi Cohen",
        grade: envelopeData?.grade || "",
        isActive: envelopeData?.isActive || false,
        minyanPoints: envelopeData?.minyanPoints || 0,
        minyanPointsHistory: envelopeData?.minyanPointsHistory || [],
        envelopeId: envelopeSnapshot.id,
        winnerList: envelopeData.winnerList || [],
        studentImgUrl: envelopeData.studentImgUrl,
      });
    } catch (error) {
      toast.error("Error fetching envelope data");
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchEnvelopeData();
  }, [envelopeId]);

  const updateProfileData = async (newData) => {
    try {
      setEnvelopes((prev) =>
        prev.map((envelope) =>
          envelope.envelopeId === envelopeId
            ? { ...envelope, ...newData }
            : envelope
        )
      );

      const envelopeDocRef = doc(db, "envelopes", envelopeId);
      await updateDoc(envelopeDocRef, {
        ...newData,
      });
    } catch (error) {
      console.log("Error updating profile data", error);
    }
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };
  const [isSelectWinnerModalOpen, setIsSelectWinnerModalOpen] = useState(false);

  const handleCloseSelectWinnerModal = () => {
    setIsSelectWinnerModalOpen(false);
  };

  const handleStudentName = (val) => {
    const nameParts = val.trim().split(" ");
    const firstName = nameParts[0] || "";
    const lastName = nameParts.slice(1).join(" ") || "";

    updateProfileData({ firstName, lastName });
  };

  const handleInputChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.id]: e.target.value,
    });
    updateProfileData({ [e.target.id]: e.target.value });
  };

  const handleActiveChange = () => {
    setProfileData({
      ...profileData,
      isActive: !profileData.isActive,
    });
    updateProfileData({ isActive: !profileData.isActive });
  };

  const handleSelectChange = (id, value) => {
    setProfileData({
      ...profileData,
      [id]: value,
    });
    updateProfileData({ [id]: value });
  };

  const handleRemovePoints = () => {
    if (buyPoints === 5) {
      return;
    }
    setBuyPoints(buyPoints - 5);
  };

  const handleAddPoints = () => {
    setBuyPoints(buyPoints + 5);
  };

  const handleBuy = () => {
    const newBuyPoints = {
      points: buyPoints,
      type: "remove",
      method: "purchase",
      timeStamp: new Date().toISOString(),
    };

    handlePoints(newBuyPoints);
  };

  const handlePoints = (newPoints) => {
    const updatedMinyanPoints =
      newPoints.type === "add"
        ? parseInt(profileData.minyanPoints) + parseInt(newPoints.points)
        : parseInt(profileData.minyanPoints) - parseInt(newPoints.points);
    setProfileData({
      ...profileData,
      minyanPoints: updatedMinyanPoints,
      minyanPointsHistory: [newPoints, ...profileData.minyanPointsHistory],
    });

    const envelopeDocRef = doc(db, "envelopes", envelopeId);
    updateDoc(envelopeDocRef, {
      minyanPoints: updatedMinyanPoints,
      minyanPointsHistory: [newPoints, ...profileData.minyanPointsHistory],
    });
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    try {
      toast.loading("Deleting envelope...");

      const envelopeDocRef = doc(db, "envelopes", envelopeId);
      await deleteDoc(envelopeDocRef);

      setEnvelopes((prev) =>
        prev.filter((envelope) => envelope.envelopeId !== envelopeId)
      );

      const path = profileData.studentImgUrl;
      await deleteImage(path);

      toast.dismiss();
      toast.success("Envelope deleted successfully");
      setShowDeleteModal(false);
      navigate("/admin/envelopes");
    } catch (error) {
      toast.dismiss();
      toast.error("Error deleting profile");
      console.log("Error deleting profile", error);
    }
  };

  const handleAddWinner = async (winnerCards, id, selectedDropdownItem) => {
    try {
      toast.loading("Adding winner...");
      const selectedCard = winnerCards[id - 1];

      if (!winnerCards || !winnerCards[id - 1]) {
        toast.dismiss();
        toast.error("Invalid winnerCards or id");
        return;
      }

      if (!selectedCard.prizeImg) {
        toast.dismiss();
        toast.error("Please upload an image for the prize card");
        return;
      }
      const newWinnerCards = {
        prizeName: selectedCard.name,
        studentId: envelopeId,
        winnerImg: profileData?.studentImgUrl,
        weekName: selectedDropdownItem,
        prizeId: selectedCard.prizeId,
      };

      const existingWinner = profileData.winnerList.find(
        (winner) =>
          winner.weekName === selectedDropdownItem &&
          winner.prizeId !== newWinnerCards.prizeId &&
          winner.studentId === envelopeId
      );

      if (existingWinner) {
        toast.dismiss();
        toast.error("Winner already exists for this envelope and week");
        return;
      }

      if (selectedCard.studentId && selectedCard.studentId !== envelopeId) {
        const oldEnvelopeDocRef = doc(db, "envelopes", selectedCard.studentId);
        const oldEnvelopeDoc = await getDoc(oldEnvelopeDocRef);
        if (!oldEnvelopeDoc.exists()) {
          toast.dismiss();
          toast.error("Old envelope document does not exist");
          return;
        }
        const oldEnvelopeData = oldEnvelopeDoc.data();

        let winnerList = oldEnvelopeData.winnerList || [];
        const winnerIndex = winnerList.findIndex(
          (winner) =>
            winner.prizeId === selectedCard.prizeId &&
            winner.weekName === selectedDropdownItem
        );

        if (winnerIndex !== -1) {
          winnerList.splice(winnerIndex, 1);

          await updateDoc(oldEnvelopeDocRef, {
            winnerList: winnerList,
          });
          toast.success("Removed winner from old envelope");
        }
      }

      winnerCards[id - 1].studentId = envelopeId;
      const envelopeDocRef = doc(db, "envelopes", envelopeId);
      const envelopeDoc = await getDoc(envelopeDocRef);
      if (!envelopeDoc.exists()) {
        toast.dismiss();
        toast.error("Current envelope document does not exist");
        return;
      }
      let winnerList = envelopeDoc.data().winnerList || [];
      winnerList.push(newWinnerCards);

      await updateDoc(envelopeDocRef, {
        winnerList: winnerList,
      });

      setProfileData({
        ...profileData,
        winnerList: winnerList,
      });

      const weekId = await findWeekIdByName(selectedDropdownItem);
      if (!weekId) {
        toast.dismiss();
        toast.error("Week not found");
        return;
      }

      const weekDocRef = doc(db, "weeks", weekId);
      const weekDoc = await getDoc(weekDocRef);
      if (!weekDoc.exists()) {
        toast.dismiss();
        toast.error("Week document does not exist");
        return;
      }

      const currentWinners = weekDoc.data().prizes || [];

      const updatedWinners = currentWinners.map((currentWinner, index) => {
        if (currentWinner.prizeId === newWinnerCards.prizeId) {
          return {
            prizeId: selectedCard.prizeId,
            prizeName: selectedCard.name,
            prizeImg: selectedCard.prizeImg,
            studentId: envelopeId,
            winnerImg: profileData.studentImgUrl,
          };
        }
        return currentWinner;
      });

      await updateDoc(weekDocRef, {
        prizes: updatedWinners,
      });

      toast.dismiss();
      toast.success("Winner added successfully");
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to add winner. Please try again.");
      console.log("Error adding winner:", error);
    }
  };

  const handleEditWinner = async (
    winnerCards,
    id,
    selectedDropdownItem,
    prevWeek,
    prevPrizeId
  ) => {
    try {
      toast.loading("Editing winner...");
      const selectedCard = winnerCards[id - 1];
      if (!selectedCard.prizeImg) {
        toast.dismiss();
        toast.error("Please upload an image for the prize card");
        return;
      }

      let prevCardData = profileData.winnerList.find(
        (winner) => winner.weekName === prevWeek
      );

      prevCardData = {
        ...prevCardData,
        prizeName: selectedCard.name,
        prizeId: selectedCard.prizeId,
        weekName: selectedDropdownItem,
      };

      if (selectedCard.studentId && selectedCard.studentId !== envelopeId) {
        const oldEnvelopeDocRef = doc(db, "envelopes", selectedCard.studentId);
        const oldEnvelopeDoc = await getDoc(oldEnvelopeDocRef);
        if (!oldEnvelopeDoc.exists()) {
          toast.dismiss();
          toast.error("Old envelope document does not exist");
          return;
        }
        const oldEnvelopeData = oldEnvelopeDoc.data();

        let winnerList = oldEnvelopeData.winnerList || [];
        const winnerIndex = winnerList.findIndex(
          (winner) =>
            winner.prizeId === selectedCard.prizeId &&
            winner.weekName === selectedDropdownItem
        );

        if (winnerIndex !== -1) {
          winnerList.splice(winnerIndex, 1);

          await updateDoc(oldEnvelopeDocRef, {
            winnerList: winnerList,
          });
          toast.success("Removed winner from old envelope");
        }
      }

      setProfileData((prevData) => ({
        ...prevData,
        winnerList: prevData.winnerList.map((winner) =>
          winner.weekName === prevWeek ? prevCardData : winner
        ),
      }));

      const envelopeDocRef = doc(db, "envelopes", envelopeId);
      const envelopeDoc = await getDoc(envelopeDocRef);
      if (!envelopeDoc.exists()) {
        toast.dismiss();
        toast.error("Current envelope document does not exist");
        return;
      }

      let winnerList = envelopeDoc.data().winnerList || [];
      const winnerIndex = winnerList.findIndex(
        (winner) => winner.weekName === prevWeek
      );

      if (winnerIndex !== -1) {
        winnerList.splice(winnerIndex, 1, prevCardData);
      }

      await updateDoc(envelopeDocRef, {
        winnerList: winnerList,
      });

      const weekId = await findWeekIdByName(selectedDropdownItem);
      if (!weekId) {
        toast.dismiss();
        toast.error("Week not found");
        return;
      }

      const weekDocRef = doc(db, "weeks", weekId);
      const weekDoc = await getDoc(weekDocRef);
      if (!weekDoc.exists()) {
        toast.dismiss();
        toast.error("Week document does not exist");
        return;
      }

      const currentWinners = weekDoc.data().prizes || [];

      const updatedWinners = currentWinners.map((currentWinner, index) => {
        if (currentWinner.prizeId === prevCardData.prizeId) {
          return {
            prizeId: selectedCard.prizeId,
            prizeName: selectedCard.name,
            prizeImg: selectedCard.prizeImg,
            studentId: envelopeId,
            winnerImg: profileData.studentImgUrl,
          };
        }
        return currentWinner;
      });

      await updateDoc(weekDocRef, {
        prizes: updatedWinners,
      });

      const prevWeekId = await findWeekIdByName(prevWeek);

      const prevWeekDocRef = doc(db, "weeks", prevWeekId);
      const prevWeekDoc = await getDoc(prevWeekDocRef);
      if (!prevWeekDoc.exists()) {
        toast.dismiss();
        toast.error("Previous week document does not exist");
        return;
      }

      const prevWeekWinners = prevWeekDoc.data().prizes || [];

      const updatedPrevWeekWinners = prevWeekWinners.map(
        (currentWinner, index) => {
          if (currentWinner.prizeId === prevPrizeId) {
            return {
              prizeId: prevPrizeId,
              prizeName: currentWinner.prizeName,
              prizeImg: currentWinner.prizeImg,
              studentId: "",
              winnerImg: "",
            };
          } else {
            return currentWinner;
          }
        }
      );

      await updateDoc(prevWeekDocRef, {
        prizes: updatedPrevWeekWinners,
      });

      toast.dismiss();
      toast.success("Winner Edited successfully");
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to edit winner. Please try again.");
      console.log("Error editing winner:", error);
    }
  };

  const onRemoveWinner = async (studentId, weekName, prizeName) => {
    try {
      const envelopeDocRef = doc(db, "envelopes", studentId);

      const winnerToRemove = profileData.winnerList.find(
        (winner) =>
          winner.weekName === weekName &&
          winner.prizeName === prizeName &&
          winner.studentId === studentId
      );

      if (!winnerToRemove) {
        throw new Error("Winner not found");
      }

      await updateDoc(envelopeDocRef, {
        winnerList: arrayRemove(winnerToRemove),
      });

      findWeekIdByName(weekName).then(async (weekId) => {
        if (!weekId) {
          throw new Error("Week not found");
        }

        const weekDocRef = doc(db, "weeks", weekId);
        const weekDoc = await getDoc(weekDocRef);

        if (!weekDoc.exists()) {
          throw new Error("Week document not found");
        }

        const currentWinners = weekDoc.data().prizes || [];

        const updatedWinners = currentWinners.map((currentWinner, index) => {
          if (
            currentWinner.studentId === studentId &&
            currentWinner.prizeName === prizeName
          ) {
            return {
              studentId: "",
              winnerImg: "",
              prizeId: currentWinner.prizeId,
              prizeName: currentWinner.prizeName,
              prizeImg: currentWinner.prizeImg,
            };
          }

          return currentWinner;
        });

        updateDoc(weekDocRef, {
          prizes: updatedWinners,
        });

        setProfileData((prevData) => ({
          ...prevData,
          winnerList: prevData.winnerList.filter(
            (winner) =>
              !(winner.weekName === weekName && winner.prizeName === prizeName)
          ),
        }));
      });
    } catch (error) {
      console.log("Error removing winner:", error);
    }
  };

  const handleBackClicked = () => {
    navigate(-1);
  };
  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-[100%] w-[100%]">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col bg-white w-[98%] h-[92vh] mx-auto mt-[4vh] rounded-[15px] shadow-[0px_3.5px_5.5px_0px_rgba(0,0,0,0.05)] px-4 sm:px-6 md:px-8 lg:px-10 pt-6 md:pt-10 gap-4 md:gap-6 overflow-y-auto">
          <div className="w-full flex flex-col md:flex-row gap-2 xs:gap-5 mb-4 xs:mb-0">
            <div className="flex-col">
              <button
                className="mt-[-10px] flex items-center spacing-x-2 mb-2"
                onClick={handleBackClicked}
              >
                <IoMdArrowRoundBack /> <div className="ml-1">Back</div>
              </button>
              <div className="w-[150px] h-[150px]">
                <img
                  src={imgUrl}
                  alt="profilePic"
                  className="w-full h-full rounded-xl"
                />
              </div>
            </div>
            <div className="w-[100%] flex-col items-center justify-center mt-5">
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between px-2 sm:px-4">
                <div
                  style={{
                    fontFamily: "Helvetica",
                    fontSize: "28px",
                    lineHeight: "35px",
                    fontWeight: "700",
                  }}
                  className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-0"
                >
                  <EditableName
                    value={profileData.name}
                    onSave={handleStudentName}
                  />
                </div>

                <div className="flex flex-row sm:justify-end gap-4 sm:gap-6 items-center">
                  <div
                    className="flex items-center gap-4"
                    style={theme.typography.headerCardPercentageText}
                  >
                    <span>Active</span>
                    <div>
                      <Switch
                        id="isActive"
                        isChecked={profileData.isActive}
                        colorScheme="teal"
                        onChange={handleActiveChange}
                      />
                    </div>
                  </div>

                  <button
                    className="border-[1px] rounded-full w-[136px] h-[32px] text-[#4FD1C5] border-[#4FD1C5]"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Profile
                  </button>
                </div>
              </div>
              <div
                className="flex flex-wrap gap-[12px] min-h-[40px] items-center pb-2 mt-3"
                style={{
                  ...theme.typography.tableContent,
                }}
              >
                <FieldsWithName name="Yeshiava">
                  <div className="min-w-[196px] w-[90%] sm:w-auto">
                    <SelectField3
                      value={profileData.Yeshiava}
                      items={formattedSchools}
                      onChange={handleSelectChange}
                      id="Yeshiava"
                    />
                  </div>
                </FieldsWithName>

                <FieldsWithName name="Grade">
                  <div className="min-w-[84px] w-[90%] sm:w-auto">
                    <SelectField3
                      value={profileData.grade}
                      items={gradeData}
                      onChange={handleSelectChange}
                      id="grade"
                    />
                  </div>
                </FieldsWithName>

                <FieldsWithName name="Rebbe">
                  <div className="sm:w-auto">
                    <InputField3
                      value={profileData?.rebbe}
                      onChange={handleInputChange}
                      id="rebbe"
                    />
                  </div>
                </FieldsWithName>

                <FieldsWithName name="Birthday">
                  <div className="sm:w-auto">
                    <InputField3
                      value={profileData?.birthDate}
                      onChange={handleInputChange}
                      id="birthDate"
                    />
                  </div>
                </FieldsWithName>

                <FieldsWithName name="Email">
                  <div className="sm:w-auto">
                    <InputField3
                      value={profileData?.parentEmail}
                      onChange={handleInputChange}
                      id="parentEmail"
                    />
                  </div>
                </FieldsWithName>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-[12] gap-6 h-[85%] min-h-[250px] mt-8 sm:mt-0">
            <div className="w-full lg:w-1/3 rounded-[17px] bg-[#F8F8F8] p-6 flex flex-col items-center gap-[20px] justify-between h-[100%]">
              <div className="flex flex-col gap-[22px] w-[100%] h-[85%] sm:h-[90%]">
                <div className="flex flex-row px-[10px] items-center gap-[12px] w-[100%] h-[5%]">
                  <div
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "18px",
                      lineHeight: "25.2px",
                      fontWeight: "400",
                      color: "#2D3748",
                    }}
                    className="w-[180px] min-w-[130px]"
                  >
                    Minyan Points:
                  </div>
                  <div
                    className="flex flex-row justify-between items-center min-w-[50px] w-[100%]"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "22px",
                      lineHeight: "30.8px",
                      fontWeight: "700",
                      color: "#4FD1C5",
                    }}
                  >
                    <div>{profileData.minyanPoints}</div>
                    <FaPlus
                      onClick={() => setShowAddModal(true)}
                      className="cursor-pointer ml-2"
                    />
                  </div>
                </div>

                <div className="overflow-auto custom-scrollbar pr-[4px] w-[100%] min-w-[250px] h-[100%]">
                  <MinyanPointHistory
                    history={profileData?.minyanPointsHistory}
                  />
                </div>
              </div>

              <div
                className="w-full flex justify-between items-center border-[2px] border-[#4FD1C5] h-[51px] rounded-[130px] px-[8px] py-[6px]"
                style={{
                  fontFamily: "Helvetica",
                  fontWeight: "700",
                  color: "#4FD1C5",
                }}
              >
                <div
                  className="flex flex-row items-center gap-[10px] pl-[10px]"
                  style={{
                    fontSize: "21px",
                    lineHeight: "29.4px",
                  }}
                >
                  <IoMdRemove
                    onClick={handleRemovePoints}
                    className="cursor-pointer"
                  />
                  <div>${buyPoints}</div>
                  <FaPlus
                    onClick={handleAddPoints}
                    className="cursor-pointer"
                  />
                </div>

                <button
                  className="rounded-[130px] bg-[#4FD1C5] text-white px-2 py-1 text-center"
                  onClick={handleBuy}
                >
                  Buy
                </button>
              </div>
            </div>

            <div className="w-full lg:w-2/3 lg:h-[100%] lg:overflow-y-auto custom-scrollbar">
              <button
                className="rounded-[42px] bg-[#4FD1C5] text-white px-2 py-1 text-center w-[136px] h-[32px] mb-4"
                style={{
                  fontFamily: "Helvetica",
                  fontSize: "13px",
                  lineHeight: "19.5px",
                  fontWeight: "700",
                  color: "#FFFFFF",
                }}
                onClick={() => setIsSelectWinnerModalOpen(true)}
              >
                Add Winner of
              </button>

              <GridView
                winnerList={profileData.winnerList}
                onRemoveWinner={onRemoveWinner}
                handleAddWinner={handleAddWinner}
                handleEditWinner={handleEditWinner}
              />
            </div>
          </div>
        </div>
      )}
      <Modal isVisible={showAddModal} onClose={handleCloseAddModal}>
        <AddRemoveMinyanPointContent
          onClose={handleCloseAddModal}
          minyanPointsHistory={profileData.minyanPointsHistory}
          handlePoints={handlePoints}
        />
      </Modal>
      <Modal
        isVisible={isSelectWinnerModalOpen}
        onClose={handleCloseSelectWinnerModal}
      >
        <SelectWinnerModalContent
          handleAddWinner={handleAddWinner}
          week={week}
          onClose={handleCloseSelectWinnerModal}
        />
      </Modal>
      <Modal isVisible={showDeleteModal} onClose={handleCloseDeleteModal}>
        <DeleteAliyaModalContent
          handleDelete={handleDelete}
          name={profileData.name}
          requestType="envelope"
        />
      </Modal>
    </>
  );
};

export default Profile;
