import React from "react";

function FieldsWithName({ children, name }) {
  return (
    <div className="flex-col items-center justify-center">
      <div
        className="m-2 ml-3"
        style={{
          fontFamily: "Helvetica",
          fontSize: "11px",
          fontWeight: "400",
          lineHeight: "16.5px",
          textAlign: "left",
        }}
      >
        {name}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default FieldsWithName;
