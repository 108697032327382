import React from "react";

const Chip = ({ isActive }) => {
  return (
    <button
      className="w-[79px] h-[25px] rounded-xl items-center justify-center"
      style={{
        padding: "0px 8px 0px 8px",
        backgroundColor: isActive ? "#4FD1C5" : "#CCCCCC",
        boxShadow: "0px 2px 5.5px 0px #0000000F",
        border: "none",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "15px",
        textAlign: "center",
        fontFamily: "Helvetica",
        cursor: "text",
        color: "#FFFFFF",
      }}
    >
      {isActive ? "Active" : "Disabled"}
    </button>
  );
};

export default Chip;
