import React from "react";
import { theme } from "../../theme";
import CommonButton from "../common/CommonButton";
import toast from "react-hot-toast";
import { deleteImage } from "../../util";

const RemoveWinnerModalContent = (props) => {
  const handleRemoveWinner = async () => {
    try {
      toast.loading("Removing winner...");
      // const path = props.img;
      // await deleteImage(path);

      await props.onRemoveWinner(props.studentId, props.parsha, props.prize);

      toast.dismiss();
      toast.success("Winner removed successfully");
      props.onClose();
    } catch (error) {
      console.log("Error removing winner:", error);
      toast.dismiss();
      toast.error(error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-[20px] mb-[20px] w-full px-4 sm:px-6 md:px-8">
      <div
        className="text-center"
        style={{
          ...theme.typography.modalTitle,
          fontSize: "clamp(18px, 4vw, 24px)",
        }}
      >
        Add Minyan Points to all Selected boys
      </div>

      <div
        className="text-center"
        style={{
          ...theme.typography.modalSubTitle,
          fontSize: "clamp(14px, 3vw, 16px)",
        }}
      >
        Are you sure you want to remove Mendel Cohen as the winner of this
        prize?
      </div>

      <CommonButton
        name="Remove as Winner"
        onClick={handleRemoveWinner}
        className="w-full sm:w-auto"
      />
    </div>
  );
};

export default RemoveWinnerModalContent;
