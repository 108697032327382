import React from "react";
import { theme } from "../theme";

export const HeaderCard = ({ heading, count, percentage, subText, Icon }) => {
  const isNegative = percentage?.includes("-");

  return (
    <div
      className={`flex justify-between items-center p-4 sm:p-5 rounded-lg w-full `}
      style={{
        ...theme.dashboard.headerCardContainer,
        minHeight: '120px',
      }}
    >
      <div className="flex-grow">
        <div 
          className="mb-1 text-sm sm:text-base" 
          style={theme.dashboard.unselectedLogoText}
        >
          {heading}
        </div>
        <div style={theme.dashboard.headerCardCountText}>
          <span className="text-xl sm:text-2xl font-bold">{count}</span>{" "}
          <span 
            className="text-sm sm:text-base"
            style={{
              ...theme.dashboard.headerCardPercentageText,
              color: isNegative ? "#E53E3E" : "#48BB78"
            }}
          >
            {percentage}%
          </span>{" "}
          {subText && (
            <span 
              className="text-xs sm:text-sm"
              style={theme.dashboard.headerCardSubText}
            >
              {subText}
            </span>
          )}
        </div>
      </div>
      <div 
        className="flex justify-center items-center w-10 h-10 sm:w-12 sm:h-12 ml-4"
        style={theme.dashboard.headerCardLogoContainer}
      >
        <img src={Icon} alt={heading} className="w-6 h-6 sm:w-8 sm:h-8" />
      </div>
    </div>
  );
};