import { db } from "../db/firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { weeksData } from "../data";

export const createNewWeeksCollection = async () => {
  try {
    const weeksCollectionRef = collection(db, "weeks");

    for (const weekData of weeksData) {
      const weekDocData = {
        weekName: weekData.name,
        prizes: [
          {
            studentId: "",
            prizeName: "First Prize",
            winnerImg: "",
            prizeImg: "",
            prizeId: `prize1_${weekData.id}`,
          },
          {
            studentId: "",
            prizeName: "Second Prize",
            winnerImg: "",
            prizeImg: "",
            prizeId: `prize2_${weekData.id}`,
          },
          {
            studentId: "",
            prizeName: "Third Prize",
            winnerImg: "",
            prizeImg: "",
            prizeId: `prize3_${weekData.id}`,
          },
          {
            studentId: "",
            prizeName: "Fourth Prize",
            winnerImg: "",
            prizeImg: "",
            prizeId: `prize4_${weekData.id}`,
          },
        ],
      };

      const docRef = await addDoc(weeksCollectionRef, weekDocData);

      await updateDoc(docRef, {
        weekId: weekData.id,
      });
    }
  } catch (error) {
    console.error("Error processing week documents: ", error);
  }
};

export const getWeeksCollection = async () => {
  try {
    const weeksCollectionRef = collection(db, "weeks");
    const querySnapshot = await getDocs(weeksCollectionRef);

    const weeksData = querySnapshot.docs.map((doc) => doc.data());
    return weeksData;
  } catch (error) {
    console.error("Error fetching weeks collection: ", error);
  }
};

export const getWeekDataByName = async (weekName) => {
  try {
    const weeksCollectionRef = collection(db, "weeks");
    const q = query(weeksCollectionRef, where("weekName", "==", weekName));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log(`No week found with name: ${weekName}`);
      return null;
    }
    const weekDoc = querySnapshot.docs[0];
    const weekData = { id: weekDoc.id, ...weekDoc.data() };

    return weekData;
  } catch (error) {
    console.error(`Error getting week data for ${weekName}:`, error);
    throw error;
  }
};

export const updatePrizeCards = async (weekName, prizeCards) => {
  try {
    const weekData = await getWeekDataByName(weekName);

    if (!weekData) {
      console.error(`No week found with name: ${weekName}`);
      return false;
    }

    const updatedPrizes = prizeCards.map((card, index) => ({
      prizeId: `prize${index + 1}_${weekData.weekId}`,
      studentId: card.studentId ,
      prizeName: card.name,
      prizeImg: card.imgUrl,
      winnerImg: card.winnerImg,
    }));

    const weekDocRef = doc(db, "weeks", weekData.id);
    await updateDoc(weekDocRef, {
      prizes: updatedPrizes,
    });

    return true;
  } catch (error) {
    console.error("Error updating prize cards: ", error);
    throw error;
  }
};

export const fetchPrizesByWeekName = async (weekName) => {
  try {
    const weeksCollectionRef = collection(db, "weeks");

    const q = query(weeksCollectionRef, where("weekName", "==", weekName));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log(`No week found with name: ${weekName}`);
      return null;
    }

    const weekDoc = querySnapshot.docs[0];
    const weekData = weekDoc.data();

    const prizesData = weekData.prizes || [];

    return prizesData;
  } catch (error) {
    console.error(`Error fetching prizes data for ${weekName}:`, error);
    throw error;
  }
};


export const updateWinnerCards = async (weekName, winnerCards) => {
  try {
    const weekData = await getWeekDataByName(weekName);

    if (!weekData) {
      console.error(`No week found with name: ${weekName}`);
      return false;
    }

    const updatedPrizes = winnerCards.map((card, index) => ({
      prizeId: `prize${index + 1}_${weekData.weekId}`,
      studentId: card.studentId || "",
      winnerImg: card.imgUrl || "" ,
      prizeImg: card.prizeImg,
      prizeName: card.name
    }));


    const weekDocRef = doc(db, "weeks", weekData.id);
    await updateDoc(weekDocRef, {
      prizes: updatedPrizes,
    });

    return true;
  } catch (error) {
    console.error("Error updating prize cards: ", error);
    throw error;
  }
};

