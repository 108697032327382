import React from "react";

function IconWithName({ Icon, name, color, onClick }) {
  return (
    <button className="flex justify-center items-center" onClick={onClick}>
      <Icon size={16} color={color} />
      <span
        className="ms-1 mt-1"
        style={{
          color: color,
          fontFamily: "Helvetica",
          fontWeight: "700",
          fontSize: "11px",
          lineHeight: "15px",
          textAlign: "center",
        }}
      >
        {name}
      </span>
    </button>
  );
}

export default IconWithName;
