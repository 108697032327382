import { theme } from "@chakra-ui/react";
import React from "react";

const InputField = (props) => {
  return (
    <div className="flex flex-col gap-[4px]">
      <div
        className="pl-[18px]"
        style={{
          lineHeight: "17.37px",
          color: "#AFAFAF",
        }}
      >
        {props.label}
      </div>
      <input
        type={props.type || "text"}
        className="focus:outline-none h-[58px] rounded-xl px-[25px] py-[18px]"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        style={{fontSize: "16px", color: "#2D3748", fontFamily: "Helvetica", lineHeight: "24px", fontWeight: "700"}}
      />
    </div>
  );
};

export default InputField;
