import React from "react";
import { theme } from "../theme";
import ViewDetailButton from "./common/ViewDetails";
import { useData } from "../context/data";

const TableHome = () => {
  const { envelopes } = useData();
  const columns = ["Name", "School", "Grade", ""];

  return (
    <table className="w-full border-collapse">
      <colgroup>
        <col style={{ width: "23%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "10%" }} />
        <col style={{ width: "10%" }} />
      </colgroup>
      <thead>
        <tr
          style={{
            position: "sticky",
            top: "0px",
            verticalAlign: "middle",
            backgroundColor: "#FFFFFF",
          }}
        >
          {columns.map((column, index) => (
            <th
              key={index}
              style={{
                ...theme.typography.tableColumnHeading,
                borderBottom: "1px solid #E2E8F0",
              }}
              className="pb-[13px]"
            >
              {index === 0 ? (
                <div
                  style={{ ...theme.typography.tableColumnHeading }}
                  className="px-5"
                >
                  {column}
                </div>
              ) : index === 3 ? (
                <div className="flex flex-row items-center gap-[10px] hover:cursor-pointer">
                  <div style={{ ...theme.typography.tableColumnHeading }}>
                    {column}
                  </div>
                </div>
              ) : (
                column
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {envelopes
          .sort(
            (a, b) =>
              new Date(b.createdAt.seconds) - new Date(a.createdAt.seconds)
          )
          .map((data, index) => (
            <tr
              key={index}
              className="h-[62px]"
              style={{
                ...theme.typography.tableContent,
                borderBottom:
                  index !== envelopes.length - 1 ? "1px solid #E2E8F0" : "none",
              }}
            >
              <td className="px-5">{data.firstName + " " + data.lastName}</td>
              <td>{data.Yeshiava}</td>
              <td>{data.grade}</td>
              <td>
                <ViewDetailButton envelopeId={data.envelopeId} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default TableHome;
