import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from "react-icons/ai";
import { theme } from "../../theme";
import LeftLayout from "./LeftLayout";
import { routes } from "../../routes";

const Layout = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      className="flex flex-col md:flex-row h-screen w-screen"
      style={{ background: theme.colors.layoutBackground }}
    >
      {/* Mobile menu button */}
      <button
        className="p-4 md:hidden text-white bg-[#4FD1C5]"
        onClick={toggleMobileMenu}
      >
        {isMobileMenuOpen ? (
          <AiOutlineMenuFold size={28} />
        ) : (
          <AiOutlineMenuUnfold size={28} />
        )}
      </button>

      {/* Left layout */}
      <div
        className={`${
          isMobileMenuOpen ? "block" : "hidden"
        } md:block md:w-1/5 lg:w-[18%] h-screen`}
      >
        <div className="flex flex-col h-screen pl-[5%] py-[40px]">
          <LeftLayout closeMobileMenu={() => setIsMobileMenuOpen(false)} />
        </div>
      </div>

      {/* Main content */}
      <div className="flex flex-col h-screen w-full md:w-4/5 lg:w-[82%]">
        <Routes>
          {routes.map(
            ({ layout, pages }) =>
              layout === "dashboard" &&
              pages.map(({ path, element }) => (
                <Route path={path} element={element} />
              ))
          )}
        </Routes>
      </div>
    </div>
  );
};

export default Layout;
