import {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "./db/firebase";

export const uploadImage = async (file, path) => {
  try {
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  } catch (error) {
    console.error("Error uploading image: ", error);
  }
};

export const deleteImage = async (imgUrl) => {
  try {
    const storageRef = ref(storage, imgUrl);
    await deleteObject(storageRef);
  } catch (error) {
    console.error("Error deleting image: ", error);
  }
};
