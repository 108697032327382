import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import PrizeCard from "../common/PrizeCard";
import { weeksDropdownData2 } from "../../data";
import SelectField3 from "../common/SelectField3";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { uploadImage, deleteImage } from "../../util";
import { fetchPrizesByWeekName, updateWinnerCards } from "../../api/prizesAPI";
import CommonButton from "../common/CommonButton";

function SelectWinnerModalContent({
  handleAddWinner,
  handleEditWinner,
  week,
  isUpdate,
  prizeId,
  onClose,
}) {
  const [winnerCards, setWinnerCards] = useState([
    {
      id: 1,
      name: "",
      selectedFile: null,
      imgUrl: null,
      studentId: "",
      prizeImg: "",
      prizeId: "",
    },
    {
      id: 2,
      name: "",
      selectedFile: null,
      imgUrl: null,
      studentId: "",
      prizeImg: "",
      prizeId: "",
    },
    {
      id: 3,
      name: "",
      selectedFile: null,
      imgUrl: null,
      studentId: "",
      prizeImg: "",
      prizeId: "",
    },
    {
      id: 4,
      name: "",
      selectedFile: null,
      imgUrl: null,
      studentId: "",
      prizeImg: "",
      prizeId: "",
    },
  ]);
  const [selectedCardId, setSelectedCardId] = useState(
    prizeId ? parseInt(prizeId[5]) : null
  );
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(week);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWinners = async () => {
      setIsLoading(true);
      try {
        const winnersData = await fetchPrizesByWeekName(selectedDropdownItem);
        if (winnersData) {
          const formattedWinnerCards = winnersData.map((winner, index) => ({
            id: index + 1,
            name: winner.prizeName ?? "Unknown Prize",
            imgUrl: winner.winnerImg,
            selectedFile: null,
            studentId: winner.studentId,
            prizeImg: winner.prizeImg,
            prizeId: winner.prizeId,
          }));
          setWinnerCards(formattedWinnerCards);
        }
      } catch (error) {
        console.error("Error fetching winners data: ", error);
        toast.error("Failed to fetch winners data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchWinners();
  }, [selectedDropdownItem]);

  // useEffect(() => {
  //   const saveChanges = async () => {
  //     try {
  //       await updateWinnerCards(selectedDropdownItem, winnerCards);
  //     } catch (error) {
  //       console.error("Error saving winner card: ", error);
  //       toast.error("Failed to save winner card");
  //     }
  //   };
  //   const debounceTimer = setTimeout(saveChanges, 1000);
  //   return () => clearTimeout(debounceTimer);
  // }, [winnerCards, selectedDropdownItem]);

  const handleMonthChange = (id, value) => {
    setSelectedDropdownItem(value);
  };

  // const handleFileChange = async (id, file) => {
  //   try {
  //     toast.loading("Uploading image...");
  //     const path = `winners/${uuidv4()}_${selectedDropdownItem}_${file.name}`;
  //     const imgUrl = await uploadImage(file, path);

  //     setWinnerCards((prevCards) =>
  //       prevCards.map((card) =>
  //         card.id === id ? { ...card, imgUrl, selectedFile: file } : card
  //       )
  //     );
  //     toast.dismiss();
  //     toast.success("Image uploaded successfully");
  //   } catch (error) {
  //     toast.dismiss();
  //     console.error("Error uploading image: ", error);
  //     toast.error("Failed to upload image");
  //   }
  // };

  // const handleDelete = async (id) => {
  //   try {
  //     toast.loading("Deleting image...");
  //     const path = winnerCards.find((card) => card.id === id).imgUrl;
  //     if (path) {
  //       await deleteImage(path);
  //     }
  //     setWinnerCards((prevCards) =>
  //       prevCards.map((card) =>
  //         card.id === id ? { ...card, imgUrl: "", selectedFile: null } : card
  //       )
  //     );
  //     toast.dismiss();
  //     toast.success("Image deleted successfully");
  //   } catch (error) {
  //     toast.dismiss();
  //     console.error("Error deleting image: ", error);
  //     toast.error("Failed to delete image");
  //   }
  // };

  const handleCardClick = async (id) => {
    setSelectedCardId(id);
  };

  const handleWinnerSelection = async (id) => {
    if (isUpdate) {
      await handleEditWinner(
        winnerCards,
        id,
        selectedDropdownItem,
        week,
        prizeId
      );
    } else {
      await handleAddWinner(winnerCards, id, selectedDropdownItem);
    }

    onClose();
  };

  return (
    <div className="flex flex-col gap-4 sm:gap-6 w-full max-w-[900px] h-full max-h-[90vh] items-center p-4 sm:p-6 overflow-y-auto custom-scrollbar">
      <div
        className="text-lg sm:text-xl md:text-2xl font-bold text-center"
        style={theme.prizes.selectWinnerModalHeading}
      >
        Select Week and Prize
      </div>
      <div
        className="w-full max-w-[300px]"
        style={theme.prizes.DropdownContainer}
      >
        <SelectField3
          value={selectedDropdownItem}
          backgroundColor={"#FFFFFF"}
          items={weeksDropdownData2}
          onChange={handleMonthChange}
          id={"week"}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 w-full">
        {winnerCards.map((card) => (
          <div key={card.id} className="w-full h-[35vh] lg:h-[60vh]">
            <PrizeCard
              id={card.id}
              img={card.prizeImg}
              name={card.name}
              backgroundColor={"#FFFFFF"}
              // onFileChange={(file) => handleFileChange(card.id, file)}
              // onDelete={() => handleDelete(card.id)}
              onCardClick={() => handleCardClick(card.id)}
              isSelected={selectedCardId === card.id}
            />
          </div>
        ))}
      </div>

      <CommonButton
        name={isUpdate ? "Update Winner" : "Add Winner"}
        onClick={() => handleWinnerSelection(selectedCardId)}
        disabled={!(selectedDropdownItem && selectedCardId)}
      />
    </div>
  );
}

export default SelectWinnerModalContent;
