import React, { useState } from "react";
import { MdEdit } from "react-icons/md";

const EditableName = ({ value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleIconClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSave = () => {
    setIsEditing(false);
    onSave(inputValue);
  };

  return (
    <div className="flex items-center">
      {isEditing ? (
        <>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            autoFocus
            className="flex-grow outline-none px-2"
          />
          <button className="text-[#4FD1C5]" onClick={handleSave}>
            Save
          </button>
        </>
      ) : (
        <>
          <span
            className="pr-2 text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-0"
            style={{
              fontFamily: "Helvetica",
              fontSize: "28px",
              lineHeight: "35px",
              fontWeight: "700",
            }}
          >
            {inputValue}
          </span>
          <MdEdit
            className="w-5 h-5 text-gray-500 cursor-pointer"
            onClick={handleIconClick}
          />
        </>
      )}
    </div>
  );
};

export default EditableName;
