import React from "react";

const InputField3 = (props) => {
  return (
    <input
      className={
        props.className ||
        `border-none focus:outline-none bg-[#F8F8F8] h-[30px] rounded-xl text-center px-2 inline-block min-w-[110px]`
      }
      value={props.value}
      onChange={props.onChange}
      id={props.id}
      style={{
        width: `${props.value.length + 2}ch`,
        ...props.style,
      }}
    />
  );
};

export default InputField3;
