import React from "react";

const CommonButton = (props) => {
  return (
    <button
      className={`border-[2px] border-[#4FD1C5] rounded-lg px-6 h-[41px] ${
        props.disabled
          ? "cursor-not-allowed"
          : "hover:bg-[#4FD1C5] hover:text-white"
      } text-[#4FD1C5] ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled ?? false}
    >
      <div
        style={{
          fontFamily: "Helvetica",
          fontWeight: 700,
          lineHeight: "24px",
          textAlign: "center",
        }}
      >
        {props.name}
      </div>
    </button>
  );
};

export default CommonButton;
