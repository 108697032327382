import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import { HeaderCard } from "../components/dashboardHomeComponents";
import wallet from "../assets/svg/wallet.svg";
import globe from "../assets/svg/globe.svg";
import page from "../assets/svg/page.svg";
import NewEnvelopeHome from "../components/NewEnvelopeHome";
import AliyaRequestsHome from "../components/AliyaRequestsHome";
import { useData } from "../context/data";
import { Spinner } from "@chakra-ui/react";

const Dashboard = () => {
  const { dataLoading, dashboardRegisteredData } = useData();

  if (dataLoading) {
    return (
      <div className="flex w-[100%] h-[100%] justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full px-4 sm:px-6 md:w-[98%] md:mr-[1%] h-screen md:h-[92vh] md:mt-[4vh] overflow-hidden">
      {/* <div className="flex flex-col sm:flex-row w-full justify-between gap-4 mb"> */}
      <div className="hidden sm:flex flex-col sm:flex-row w-full justify-between gap-4 mb">
        <HeaderCard
          heading="Boys Registered in minyan"
          count={dashboardRegisteredData.total || 0}
          percentage={dashboardRegisteredData.percentage || "+0"}
          subText="in past 7 days"
          Icon={wallet}
        />
        <HeaderCard
          heading="Today's Users"
          count={"2,300"}
          percentage={"+5"}
          subText={""}
          Icon={globe}
        />
        <HeaderCard
          heading="Donations"
          count={"$3,600"}
          percentage={"-2"}
          subText={"in past 7 days"}
          Icon={page}
        />
      </div>
      <div className="flex flex-col lg:flex-row justify-between gap-4 mt-5 flex-grow overflow-hidden">
        <div className="w-full lg:w-[49%] bg-white rounded-xl px-4 sm:px-8 py-6 shadow-[0px_3.5px_5.5px_0px_rgba(0,0,0,0.05)] overflow-hidden flex flex-col min-h-[350px]">
          <div className="overflow-y-auto flex-grow custom-scrollbar">
            <NewEnvelopeHome />
          </div>
        </div>
        <div className="w-full lg:w-[49%] bg-white rounded-xl px-4 sm:px-8 py-6 shadow-[0px_3.5px_5.5px_0px_rgba(0,0,0,0.05)] overflow-hidden flex flex-col min-h-[400px]">
          <div className="overflow-y-auto flex-grow custom-scrollbar">
            <AliyaRequestsHome />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
