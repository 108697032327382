import { Outlet, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Spinner } from "@chakra-ui/react";
import { useAuth } from "../context/auth";

const PrivateRoute = () => {
  const { admin, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (admin) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
