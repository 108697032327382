import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { db } from "../db/firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  Timestamp,
  query,
  where,
} from "firebase/firestore";
import { useAuth } from "./auth";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [dataLoading, setDataLoading] = useState(true);
  const [envelopes, setEnvelopes] = useState([]);
  const [dashboardRegisteredData, setDashboardRegisteredData] = useState({});
  const { admin } = useAuth();
  const [requests, setRequests] = useState([]);
  const [schools, setSchools] = useState([]);
  const [week, setWeek] = useState(null);

  const viewEnvelopes = async () => {
    setDataLoading(true);
    try {
      const envelopesCollectionRef = collection(db, "envelopes");
      const envelopesSnapshot = await getDocs(envelopesCollectionRef);

      const envelopesData = envelopesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        envelopeId: doc.id,
      }));
      setEnvelopes(envelopesData);
    } catch (error) {
      throw error;
    } finally {
      setDataLoading(false);
    }
  };

  const getDashboardBoysRegistered = async () => {
    setDataLoading(true);
    try {
      const now = Timestamp.now();
      const weekAgo = Timestamp.fromDate(
        new Date(now.toDate().setDate(now.toDate().getDate() - 7))
      );

      const envelopesCollectionRef = collection(db, "envelopes");
      const envelopesQuery = query(
        envelopesCollectionRef,
        where("createdAt", ">=", weekAgo)
      );

      const past7DaysEnvelopesSnapshot = await getDocs(envelopesQuery);
      const totalEnvelopesSnapshot = await getDocs(envelopesCollectionRef);

      const changeInEnvelopes =
        totalEnvelopesSnapshot.size - past7DaysEnvelopesSnapshot.size;
      const percentageChange =
        ((changeInEnvelopes / past7DaysEnvelopesSnapshot.size) * 100).toFixed(2);

      setDashboardRegisteredData({
        total: totalEnvelopesSnapshot.size,
        change: changeInEnvelopes,
        percentage:
          percentageChange > 0 ? `+${percentageChange}` : percentageChange,
      });
    } catch (error) {
      throw error;
    } finally {
      setDataLoading(false);
    }
  };

  const getRequests = async () => {
    setDataLoading(true);
    try {
      const requestsCollectionRef = collection(db, "requests");
      const requestsSnapshot = await getDocs(requestsCollectionRef);

      const requestsData = requestsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        requestId: doc.id,
      }));
      setRequests(requestsData);
    } catch (error) {
      throw error;
    } finally {
      setDataLoading(false);
    }
  };

  const fetchSchools = async () => {
    setDataLoading(true);
    try {
      const schoolsCollectionRef = collection(db, "common/general/schools");
      const schoolsSnapshot = await getDocs(schoolsCollectionRef);

      const schoolList = schoolsSnapshot.docs.map((doc) => ({
        schoolId: doc.id,
        ...doc.data(),
      }));

      setSchools(schoolList);
    } catch (error) {
      throw error;
    } finally {
      setDataLoading(false);
    }
  };

  const fetchWeek = async () => {
    try {
      setDataLoading(true);
      const weekDocRef = doc(db, "common", "week");
      const weekDoc = await getDoc(weekDocRef);
      const weekData = weekDoc.data();
      setWeek(weekData.selectedWeek);
    } catch (error) {
      throw error;
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (admin) {
      viewEnvelopes();
      getDashboardBoysRegistered();
      getRequests();
      fetchSchools();
      fetchWeek();
    }
  }, [admin]);

  const dataValue = {
    dataLoading,
    envelopes,
    dashboardRegisteredData,
    requests,
    setRequests,
    setEnvelopes,
    schools,
    setSchools,
    week,
    setWeek
  };

  return (
    <DataContext.Provider value={dataValue}>{children}</DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useData = () => useContext(DataContext);

export { DataProvider, useData };
