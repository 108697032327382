import React, { useState } from "react";
import { theme } from "../../theme";
import { CgTrash } from "react-icons/cg";
import uploadTemp from "../../assets/svg/uploadTemp.svg";
import InputField3 from "./InputField3";

function PrizeCard({
  id,
  img,
  name,
  backgroundColor,
  onFileChange,
  onDelete,
  onNameChange,
  onCardClick,
  isSelected,
}) {
  const [editingName, setEditingName] = useState(false);

  const handleFileChange = (event) => {
    onFileChange(event.target.files[0]);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const handleNameChange = (event) => {
    onNameChange(event.target.value);
  };

  return (
    <div
      className="flex flex-col items-center w-full h-full"
      style={{
        ...theme.prizes.prizeCardContainer,
        backgroundColor,
        border: isSelected ? "3px solid #4FD1C5" : "none",
      }}
      onClick={onCardClick}
    >
      {img ? (
        <div className="w-full h-[80%] relative">
          <img
            src={img}
            alt="prize"
            className="w-full h-full object-cover rounded-xl"
          />
          {onDelete && (
            <div className="absolute bottom-2 left-2">
              <CgTrash
                size={24}
                color="#ffffff"
                onClick={handleDelete}
                className="cursor-pointer"
              />
            </div>
          )}
        </div>
      ) : onFileChange ? (
        <div className="w-full h-[80%] bg-gray-300 rounded-xl flex flex-col justify-center items-center">
          <label
            htmlFor={`file-input-${id}`}
            className="w-full h-full flex flex-col justify-center items-center cursor-pointer"
          >
            <img src={uploadTemp} alt="uploadTemp" className="w-1/3 h-auto" />
            <div
              className="text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl"
              style={theme.prizes.prizeCardUploadText}
            >
              Upload Prize image
            </div>
          </label>
          <input
            id={`file-input-${id}`}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
      ) : (
        <div className="w-full h-[80%] bg-gray-300 rounded-xl flex flex-col justify-center items-center">
          {" "}
        </div>
      )}
      {onNameChange ? (
        <InputField3
          value={name}
          onChange={handleNameChange}
          className="w-full min-w-[100px] mt-2 text-center text-xs sm:text-sm md:text-base lg:text-xl xl:text-xl px-2 border-none focus:outline-none bg-[#f8f8f8]"
          style={theme.prizes.prizeCardText}
        />
      ) : (
        <div
          className="w-full min-w-[220px] mt-2 text-center text-xs sm:text-sm md:text-base lg:text-xl xl:text-xl px-2"
          style={theme.prizes.prizeCardText}
        >
          {name}
        </div>
      )}
    </div>
  );
}

export default PrizeCard;
