import Dashboard from "./pages/dashboard";
import Envelopes from "./pages/Envelopes";
import Prizes from "./pages/Prizes";
import Setting from "./pages/Setting";
import Login from "./pages/login";
import Profile from "./pages/Profile";

export const routes = [
  {
    layout: "dashboard",
    pages: [
      {
        name: "Dashboard",
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        name: "Envelopes",
        path: "/envelopes",
        element: <Envelopes />,
      },
      {
        name: "Prizes",
        path: "/prizes",
        element: <Prizes />,
      },
      {
        name: "Setting",
        path: "/setting",
        element: <Setting />,
      },
      {
        name: "ProfileFromDashboard",
        path: "/dashboard/profile/:envelopeId",
        element: <Profile />,
      },
      {
        name: "ProfileFromEnvelopes",
        path: "/envelopes/profile/:envelopeId",
        element: <Profile />,
      },
    ],
  },
  {
    layout: "auth",
    pages: [
      {
        name: "Login",
        path: "/login",
        element: <Login />,
      },
    ],
  },
];
