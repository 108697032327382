import React, { useState } from "react";
import { theme } from "../../theme/index.js";
import InputField from "../common/InputField";
import CancelButton from "../common/CancelButton";
import CommonButton from "../common/CommonButton";
import toast from "react-hot-toast";
import { db } from "../../db/firebase.js";
import {
  addDoc,
  updateDoc,
  collection,
  doc,
  deleteDoc,
} from "firebase/firestore";

function AddEditSchoolModalContent({
  onClose,
  selectedSchool,
  schools,
  setSchools,
}) {
  const [schoolName, setSchoolName] = useState(selectedSchool?.name || "");

  const handleSave = async () => {
    toast.loading("Saving...");
    try {
      if (selectedSchool) {
        await updateSchool(selectedSchool.schoolId, schoolName);
      } else {
        await addSchool(schoolName);
      }
      toast.dismiss();
      toast.success("School saved successfully");
    } catch (error) {
      toast.dismiss();
      toast.error("Error saving school");
      console.log("Error saving school: ", error);
    } finally {
      onClose();
    }
  };

  const updateSchool = async (id, name) => {
    try {
      const schoolDocRef = doc(db, "common/general/schools", id);
      await updateDoc(schoolDocRef, { name });

      const updatedSchools = schools.map((school) =>
        school.schoolId === id ? { ...school, name } : school
      );
      setSchools(updatedSchools);
    } catch (error) {
      throw error;
    }
  };

  const addSchool = async (name) => {
    try {
      const schoolsCollectionRef = collection(db, "common/general/schools");
      const schoolDocRef = await addDoc(schoolsCollectionRef, { name });
      await updateDoc(schoolDocRef, { schoolId: schoolDocRef.id });

      const newSchool = { schoolId: schoolDocRef.id, name };
      setSchools([...schools, newSchool]);
    } catch (error) {
      throw error;
    }
  };

  const handleDelete = async () => {
    try {
      toast.loading("Deleting...");
      const schoolDocRef = doc(
        db,
        "common/general/schools",
        selectedSchool.schoolId
      );
      await deleteDoc(schoolDocRef);

      const updatedSchools = schools.filter(
        (school) => school.schoolId !== selectedSchool.schoolId
      );
      setSchools(updatedSchools);
      toast.dismiss();
      toast.success("School deleted successfully");
      onClose();
    } catch (error) {
      toast.dismiss();
      toast.error("Error deleting school");
    }
  };

  return (
    <div className="flex flex-col items-center gap-[20px] mb-[20px] w-full max-w-[350px] mx-auto px-4">
      <div style={{ ...theme.typography.modalTitle, textAlign: "center" }}>
        {selectedSchool ? "Edit School" : "Add School"}
      </div>
      <div className="w-full">
        <InputField
          label="School Name"
          name="schoolName"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
        />
      </div>
      <div className="flex flex-col sm:flex-row gap-[14px] w-full justify-center">
        <CancelButton
          name="Cancel"
          onClick={onClose}
          className="w-full sm:w-auto"
        />
        <CommonButton
          name={selectedSchool ? "Save" : "Add"}
          onClick={handleSave}
          className="w-full sm:w-auto"
        />
      </div>
      {selectedSchool && (
        <button
          style={{
            lineHeight: "17.37px",
            color: "#AFAFAF",
          }}
          onClick={handleDelete}
          className="mt-4"
        >
          Delete School
        </button>
      )}
    </div>
  );
}

export default AddEditSchoolModalContent;
