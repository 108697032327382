import React, { useState, useEffect } from "react";
import { theme } from "../theme";
import AliyaRequestCard from "./common/AliyaRequestCard";
import SelectField3 from "./common/SelectField3";
import { weeksDropdownData2 } from "../data";
import { useData } from "../context/data";

function AliyaRequestsHome() {
  const { requests } = useData();
  const [selectedDropdownItem, setSelectedDropdownItem] = useState();
  const [filteredRequests, setFilteredRequests] = useState(requests);

  useEffect(() => {
    if (selectedDropdownItem) {
      const filteredData = requests.filter((request) => {
        return request.parshas === selectedDropdownItem;
      });
      setFilteredRequests(filteredData);
    }
  }, [selectedDropdownItem]);

  const handleMonthChange = (id, value) => {
    setSelectedDropdownItem(value);
  };

  return (
    <div className="flex flex-col w-full h-full items-center p-4">
      <div className="w-full flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
        <div style={theme.typography.tableHeading} className="mb-2 sm:mb-0">
          Aliya Requests
        </div>
        <div
          className="flex flex-row items-center gap-[10px] hover:cursor-pointer w-full sm:w-auto min-w-[150px]"
          style={{
            height: "30px",
            width: "109px",
            padding: "0px 8px",
            borderRadius: "12px",
            background: "#FFFFFF",
            boxShadow: "0px 2px 5.5px 0px #0000000F",
            border: "none",
            cursor: "pointer",
            fontFamily: "Helvetica",
            fontWeight: 700,
            fontSize: "10px",
            lineHeight: "15px",
            textAlign: "center",
            color: "#2D3748",
          }}
        >
          <SelectField3
            value={selectedDropdownItem}
            backgroundColor={"#FFFFFF"}
            items={weeksDropdownData2}
            onChange={handleMonthChange}
          />
        </div>
      </div>
      <div className="w-full mt-3 overflow-hidden">
        <div className="h-full overflow-y-auto custom-scrollbar">
          {filteredRequests
            .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
            .map((data, index) => (
              <AliyaRequestCard
                key={index}
                name={data?.name}
                fatherName={data?.fatherName}
                parsha={data?.parshas}
                honorOf={data?.inHonorOf}
                IamA={data?.type}
                date={
                  data?.createdAt
                    ? data.createdAt.toDate().toDateString()
                    : "Date not available"
                }
                nickName={data?.nickName}
                requestId={data?.requestId}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default AliyaRequestsHome;
