import React from "react";
import { theme } from "../theme";
import { BsThreeDotsVertical } from "react-icons/bs";
import TableHome from "./TableHome";

function NewEnvelopeHome() {
  return (
    <div className="flex flex-col w-[100%] h-[100%] items-center">
      <div className="w-[100%] flex justify-between">
        <div style={theme.typography.tableHeading}>New Envelope Requests</div>
        <button className="cursor-pointer">
          <BsThreeDotsVertical size={18} color="#A0AEC0" />
        </button>
      </div>
      <div className="w-[100%] mt-7 overflow-hidden">
        <div className="h-[100%] overflow-y-auto custom-scrollbar">
          <TableHome />
        </div>
      </div>
    </div>
  );
}

export default NewEnvelopeHome;
